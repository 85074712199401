<template>
    <v-switch v-model="active" v-bind="{ ...$attrs, disabled }" @change="emit" />
</template>

<script>
import { initUser } from "@/assets/variables";
export default {
    props: {
        value: { type: Array, default: () => [] }, // scope
        item: { type: Object, default: initUser },
        code: { type: String, default: "manager" },
    },
    data: () => ({
        active: false,
        scope: [],
    }),
    computed: {
        disabled() {
            const { disabled } = this.$attrs;
            if (disabled) return true;

            const isScopeConsole = this.code == "console";
            if (isScopeConsole) {
                const isUserOneself = this.item?._id == this.$store?.state?.payload?._user;
                const isDefaultUser = ["uxidev", "admin", "developer"].includes(this.item?.username);
                return isUserOneself || isDefaultUser;
            }
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.scope = [...this.value];
            this.active = this.value.includes(this.code);
        },
        emit() {
            let items = [...this.value];

            if (this.active) items.push(this.code);
            else items = items.filter((item) => item != this.code);
            this.$emit("input", items);
        },
    },
};
</script>

<style></style>
