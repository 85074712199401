<template>
    <v-btn v-bind="{ ...$attrs, loading, disabled }" @click="commit"> {{ text }} </v-btn>
</template>

<script>
import api from "@/api";

import { initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        result: initShopBulkUploadData(),
    }),
    computed: {
        disabled() {
            return this.parsed?.categories?.length <= this.value?.categories?.length;
        },
        text() {
            return this.disabled ? "처리완료" : "등록처리";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        async commit() {
            if (this.loading) return;
            else this.$emit("setLoading", true);

            const { post, put } = api.console.shop.categories;
            try {
                for (const parsedCategory of [...this.parsed.categories].sort((a, b) => a.depth - b.depth)) {
                    try {
                        ////////////////////////////////////////////////////////////
                        // POST, PUT document
                        ////////////////////////////////////////////////////////////
                        let { parents = [], ...form } = parsedCategory;
                        let { category } = await api.v1.shop.categories.get({ code: form.code });
                        if (category) form = { ...category, ...form };

                        delete form.rawData;
                        delete form.nameData;

                        parents = await Promise.all(
                            parents.map(async ({ name, depth }) => {
                                let parent = this.result.categories.find((item) => item.name == name && item.depth == depth);
                                if (parent?._id) return parent;

                                parent = (await api.v1.shop.categories.get({ code: parent.code }))?.category;

                                if (!parent?._id) throw new Error("누락된 카테고리가 있습니다");
                            })
                        );
                        form._parent = [...parents].pop()?._id || null;

                        category = (await (category ? put : post)(form))?.category;

                        this.result.categories.push(category);
                        this.emit();
                    } catch (error) {
                        this.result.categories.push({ ...parsedCategory, errors: [...(parsedCategory.errors || []), error] });
                        this.emit();
                    }
                }
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
