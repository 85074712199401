var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.charge.type == _vm.SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value,
      expression: "form.charge.type == SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "caption",
    attrs: {
      "prefix": "고정금액",
      "suffix": "원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.charge.fixedAmount,
      callback: function ($$v) {
        _vm.$set(_vm.form.charge, "fixedAmount", $$v);
      },
      expression: "form.charge.fixedAmount"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input__verticalTable, {
    readonly: _vm.readonly
  }), false))], 1)], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.charge.type == _vm.SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value,
      expression: "form.charge.type == SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('item-charge-prices-range', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'item-charge-prices-range', {
    mode: _vm.mode
  }, false))], 1)], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.charge.type == _vm.SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value,
      expression: "form.charge.type == SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('item-charge-travel-range', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'item-charge-travel-range', {
    mode: _vm.mode
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }