<template>
    <span v-if="readonly">
        <template v-if="multiple">
            {{ item.map(({ title } = {}) => title).join(", ") || placeholder }}
        </template>
        <template v-else> {{ itemText(value) || placeholder }} </template>
    </span>
    <v-autocomplete v-else :value="value" v-bind="{ ...$attrs, items, itemText, itemValue: '_id', multiple }" @change="(changedValue) => $emit('input', changedValue)" @click="alert">
        <template #selection="{ parent, item, attrs }" v-if="$scopedSlots['selection']">
            <slot name="selection" v-bind="{ parent, item, attrs, itemText }" />
        </template>
        <slot />
    </v-autocomplete>
</template>

<script>
import api from "@/api";
import { EXHIBITION_GROUPS } from "@/assets/variables";

export default {
    props: {
        value: { type: [String, Array], default: null },
        params: { type: [Object, Array], default: () => ({}) },
        multiple: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        placeholder: { type: String, default: null },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        item() {
            if (this.multiple) return this.items.filter(({ _id }) => this.value.includes(_id));
            return this.items.find(({ _id }) => _id == this.value);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { params } = this;
            this.items = (await api.console.shop.exhibitions.gets({ params }))?.exhibitions || [];
            this.$nextTick(() => this.$emit("setExhibition"));
        },
        itemText({ group, title }) {
            let text = "";

            const groupText = EXHIBITION_GROUPS[group]?.text;
            if (groupText) text += `[${groupText}]`;
            if (title) text += ` ${title}`;
            else text += "제목 없음";

            return text;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
