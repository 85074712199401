<template>
    <v-data-table v-bind="{ items, headers }" fixed-header>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

        <template #[`item.label`]="{ value }">
            <image-popup :src="value" :attrs_avatar="{ size: 40, tile: true }" />
        </template>
        <template #[`item.thumb`]="{ value }">
            <image-popup :src="value" :attrs_avatar="{ size: 40, tile: true }" />
        </template>
        <template #[`item.image`]="{ value }">
            <image-popup :src="value" :attrs_avatar="{ size: 40, tile: true }" />
        </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";
import ImagePopup from "@/components/console/dumb/image-popup.vue";

export default {
    components: {
        ImagePopup,
    },
    props: {
        value: { type: Object, default: initShopBulkUploadData },
        isError: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return this.value?.brands || [];
        },
        headers() {
            const headers = [
                { width: 240, value: "name", text: "이름" },
                { width: 240, value: "code", text: "코드" },
                { value: "desc", text: "설명" },

                { width: +80, value: "label", text: "라벨", cellClass: "pa-0", align: "center", sortable: false },
                { width: +80, value: "thumb", text: "목록", cellClass: "pa-0", align: "center", sortable: false },
                { width: +80, value: "image", text: "상세", cellClass: "pa-0", align: "center", sortable: false },
            ];

            if (this.isError) headers.push({ width: 480, value: "errors", text: "오류", formatter: (errors) => errors.map((error) => error.message).join("\r\n") });

            return initDataTableHeaders(headers.map((header) => ({ ...header, class: (header.class || "") + " word-break-keep-all", cellClass: (header.cellClass || "") + " text-truncate max-width-0 py-3", withTitle: true })));
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .word-break-keep-all {
        word-break: keep-all;
    }
    .max-width-0 {
        max-width: 0px;
    }
}
</style>
