var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', _vm._b({}, 'v-tabs', _vm.$attrs, false), [_vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      value = _ref.value,
      to = _ref.to;
    return [_c('v-tab', _vm._b({
      key: value,
      attrs: {
        "exact": ""
      }
    }, 'v-tab', {
      value,
      to
    }, false), [_vm._v(_vm._s(text))])];
  }), _c('v-spacer')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }