var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": `${((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.title) || '배너'}`
    }
  }), _vm.banner ? _c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "outlined": ""
    }
  }, [_vm.single ? [_c('v-row', {
    staticStyle: {
      "min-height": "calc(100vh - 48px - 12px - 32px - 56px - 16px - 16px - 12px)"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticStyle: {
      "border-right": "1px solid rgba(0, 0, 0, 0.12)"
    }
  }, [_c('banner-input', {
    attrs: {
      "label": "Desktop",
      "sublabel": _vm.banner.sublabel__pc,
      "name": `${_vm.banner.title} Desktop`,
      "code": _vm.banner.code__pc
    }
  })], 1)], 1)] : [_c('v-row', {
    staticStyle: {
      "min-height": "calc(100vh - 48px - 12px - 32px - 56px - 16px - 16px - 12px)"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticStyle: {
      "border-right": "1px solid rgba(0, 0, 0, 0.12)"
    },
    attrs: {
      "cols": "6"
    }
  }, [_c('banner-input', {
    attrs: {
      "label": "Desktop",
      "sublabel": _vm.banner.sublabel__pc,
      "name": `${_vm.banner.title} Desktop`,
      "code": _vm.banner.code__pc
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('banner-input', {
    attrs: {
      "label": "Mobile",
      "sublabel": _vm.banner.sublabel__mb,
      "name": `${_vm.banner.title} Mobile`,
      "code": _vm.banner.code__mb
    }
  })], 1)], 1)]], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }