<template></template>

<script>
import XLSX from "xlsx";

import { initShopBulkUploadData } from "@/assets/variables";

const varName = "bundleds";
const sheetName = "7.세트상품 등록";

const BOOLEANS = { ["Y"]: true, ["N"]: false };

const nameData = (row) => ({
    rawData: row,

    ["상품코드"]: `${row?.A || ""}`?.trim?.() || null,
    ["세트항목 상품코드"]: `${row?.B || ""}`?.trim?.() || null,
    ["세트항목 표기이름"]: `${row?.C || ""}`?.trim?.() || null,
    ["세트항목 표기금액"]: row?.D != null ? row?.D : null,
    ["세트항목 상품개수"]: row?.E != null ? row?.E : null,
});

const structureData = ({ rawData, ...nameData }) => ({
    parent: nameData["상품코드"],
    target: nameData["세트항목 상품코드"],
    custom: {
        name: nameData["세트항목 표기이름"],
        amount: nameData["세트항목 상품개수"] != null ? +nameData["세트항목 상품개수"] : null,
        salePrice: nameData["세트항목 표기금액"] != null ? +nameData["세트항목 표기금액"] : null,
    },

    rawData,
    nameData,
});

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    mounted() {
        this.process();
    },
    watch: {
        file() {
            this.process();
        },
    },
    methods: {
        process() {
            if (!this.file) return;

            const reader = new FileReader();
            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: sheetName });
                const sheet = workBook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { header: "A", range: 4 });

                const items = rawData.map(nameData).map(structureData);

                this.$emit(
                    "input",
                    initShopBulkUploadData({
                        ...this.value,
                        [varName]: items,
                    })
                );
                this.$emit("loaded");
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>
