var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-4 py-3"
  }, [this.value.isActive ? [_c('div', {
    staticClass: "font-weight-bold grey--text"
  }, [_vm._v(_vm._s(this.value.typeText))]), _vm._v(" " + _vm._s(this.value.value.format()) + "원 이상 ")] : [_vm._v(" 조건없음 ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }