<template>
    <v-row class="ml-0">
        <template v-for="item in items">
            <v-col :key="item.text" cols="12" sm="6" md="4" lg="3" class="pl-0 pt-0">
                <v-card outlined :class="{ primary: item.isActive }" :dark="item.isActive" @click="go(item)">
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="auto">
                                <v-icon>{{ item.icon }}</v-icon> {{ item.text }}
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <span class="font-weight-bold" :class="{ 'primary--text': !item.isActive }"> {{ item.count }} </span> 건
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { PURCHASE_CLAIM_STATUSES, PURCHASE_COLLECTION_STATUSES } from "@/assets/variables";

export default {
    props: {
        dashboard: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            const { ...query } = this.$route.query;

            delete query.collectionStatus;

            query.page = 1;

            return [
                {
                    icon: "mdi-clipboard-arrow-up-outline",
                    text: "교환요청",
                    count: this.dashboard?.exchangeRequest?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value,
                            collectionStatus: null,
                        },
                    },
                },
                {
                    icon: "mdi-truck-fast-outline",
                    text: "교환수거중",
                    count: this.dashboard?.collectProgress?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value,
                            collectionStatus: PURCHASE_COLLECTION_STATUSES.COLLECTION_PROGRESS.value,
                        },
                    },
                },
                {
                    icon: "mdi-truck-check-outline",
                    text: "교환수거완료",
                    count: this.dashboard?.collectComplete?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value,
                            collectionStatus: PURCHASE_COLLECTION_STATUSES.COLLECTION_COMPLETE.value,
                        },
                    },
                },
                {
                    icon: "mdi-truck-fast",
                    text: "교환재배송",
                    count: this.dashboard?.exchangeShipping?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value,
                        },
                    },
                },
                {
                    icon: "mdi-clipboard-check-outline",
                    text: "교환완료",
                    count: this.dashboard?.exchangeComplete?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value,
                        },
                    },
                },
                {
                    icon: "mdi-clipboard-remove-outline",
                    text: "교환거부",
                    count: this.dashboard?.exchangeComplete?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value,
                        },
                    },
                },
                {
                    icon: "mdi-clipboard-alert-outline",
                    text: "교환보류",
                    count: this.dashboard?.exchangePending?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value,
                        },
                    },
                },
                {
                    icon: "mdi-truck-alert-outline",
                    text: "교환지연",
                    count: this.dashboard?.exchangeDelay?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value,
                        },
                    },
                },
            ].map((item) => {
                const { claimStatus, collectionStatus } = item.to.query;
                const sameClaimStatus = Array.isArray(claimStatus) ? !claimStatus.some((value) => !(query.claimStatus || []).includes(value)) : query.claimStatus == claimStatus;

                const sameCollectionStatus = this.$route.query.collectionStatus == collectionStatus;

                return { ...item, isActive: sameClaimStatus && sameCollectionStatus };
            });
        },
    },
    methods: {
        go({ to, isActive }) {
            if (isActive) {
                const { ...query } = this.$route.query;

                delete query.orderStatus;
                delete query.claimStatus;

                this.$router.push({ query });
            } else this.$router.push(to);
        },
    },
};
</script>

<style></style>
