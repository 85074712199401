var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({}, 'v-card', _vm.$attrs, false), [_c('v-card-text', {
    staticClass: "caption"
  }, [_c('v-row', {
    staticClass: "ma-n4",
    staticStyle: {
      "word-break": "keep-all"
    }
  }, [_c('v-col', {
    staticClass: "py-3 px-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-weight-bold grey--text"
  }, [_vm._v("발급대상")]), _vm._v(" " + _vm._s(_vm.targetTypeText) + " ")]), _c('v-col', {
    staticClass: "py-3 px-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-weight-bold grey--text"
  }, [_vm._v("대상" + _vm._s(_vm.targetTypeText))]), _vm.issue.target.type == _vm.ISSUE_TARGET_TYPES.USERS.value ? [_c('autocomplete-user', {
    attrs: {
      "value": this.issue.target._users,
      "readonly": "",
      "multiple": "",
      "placeholder": "전체"
    }
  })] : _vm._e(), _vm.issue.target.type == _vm.ISSUE_TARGET_TYPES.USER_LEVELS.value ? [_c('autocomplete-user-level', {
    attrs: {
      "value": this.issue.target._levels,
      "readonly": "",
      "multiple": "",
      "placeholder": "전체"
    }
  })] : _vm._e(), _vm.issue.target.type == _vm.ISSUE_TARGET_TYPES.USER_TYPES.value ? [_vm._v(" " + _vm._s(_vm.userTypeTexts) + " ")] : _vm._e()], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }