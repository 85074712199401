<template>
    <v-row class="ma-n1">
        <template v-if="tilesOnly">
            <template v-for="image in images">
                <v-col v-bind="{ cols }" :key="image" class="pa-1">
                    <image-tiles-dialog v-bind="{ images }">
                        <template #activator="{ attrs, on, setIndex }">
                            <v-card outlined flat color="grey" @click="setIndex(image)">
                                <v-img :src="image" :aspect-ratio="1 / 1" width="100%" v-bind="attrs" v-on="on" />
                            </v-card>
                        </template>
                    </image-tiles-dialog>
                </v-col>
            </template>
        </template>
        <template v-else>
            <v-col cols="12" class="pa-2">
                <v-tabs-items :value="selectedIndex">
                    <template v-for="(image, index) in images">
                        <v-tab-item :key="image" :value="index">
                            <image-tiles-dialog v-bind="{ images }">
                                <template #activator="{ attrs, on, setIndex }">
                                    <v-img :src="image" :aspect-ratio="1 / 1" width="100%" class="grey" v-bind="attrs" v-on="on" @click="setIndex(image)" />
                                </template>
                            </image-tiles-dialog>
                        </v-tab-item>
                    </template>
                </v-tabs-items>
            </v-col>
            <v-col cols="12" :class="{ 'order-first': reverse }" class="pa-2">
                <v-row no-gutters>
                    <template v-for="(image, index) in images">
                        <v-col v-bind="{ cols }" :key="image" class="pa-1" style="position: relative" @mouseenter="setSelectedIndex(image)">
                            <v-fade-transition>
                                <v-overlay v-show="selectedIndex == index" absolute z-index="0" color="black" opacity="1" />
                            </v-fade-transition>
                            <v-img :src="image" :aspect-ratio="1 / 1" width="100%" />
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import ImageTilesDialog from "./image-tiles-dialog.vue";

export default {
    components: {
        ImageTilesDialog,
    },
    props: {
        images: { type: Array, default: () => [] },

        cols: { type: [String, Number], default: 3 },
        reverse: { type: Boolean, default: false },
        tilesOnly: { type: Boolean, default: false },
    },
    data: () => ({
        selectedIndex: 0,
    }),
    methods: {
        setSelectedIndex(image) {
            this.selectedIndex = this.images.findIndex((item) => item == image);
        },
    },
};
</script>

<style>
</style>