var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "적용된 배송정책"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "mr-n4 my-n1",
          attrs: {
            "to": "/console/shop/shippings",
            "small": "",
            "text": "",
            "tile": "",
            "height": "54"
          }
        }, [_vm._v(" 배송정책 설정하기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_vm.loading ? [_c('v-sheet', {
    staticClass: "pa-3 d-flex align-center",
    attrs: {
      "height": "48",
      "color": "transparent"
    }
  }, [_c('span', [_vm._v(" 로드중입니다 ")])])] : _vm.shipping ? [_c('form-options', {
    attrs: {
      "value": _vm.shipping,
      "mode": "view"
    }
  })] : [_c('v-sheet', {
    staticClass: "pa-3 d-flex align-center",
    attrs: {
      "height": "48",
      "color": "transparent"
    }
  }, [_c('span', [_vm._v(" 연결된 배송정보가 없습니다 ")])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }