var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ml-0"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item.text,
      staticClass: "pl-0 pt-0",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": ""
      }
    }, [_c('v-card', {
      class: {
        primary: item.isActive
      },
      attrs: {
        "outlined": "",
        "dark": item.isActive
      },
      on: {
        "click": function ($event) {
          return _vm.go(item);
        }
      }
    }, [_c('v-card-text', {
      staticClass: "py-0"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(item.text) + " ")], 1), _c('v-spacer'), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "font-weight-bold",
      class: {
        'primary--text': !item.isActive
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _vm._v(" 건 ")])], 1)], 1)], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }