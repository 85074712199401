<template>
    <v-tabs v-bind="$attrs">
        <template v-for="{ text, value, to } in items">
            <v-tab :key="value" v-bind="{ value, to }" exact>{{ text }}</v-tab>
        </template>
        <v-spacer />
    </v-tabs>
</template>

<script>
import { USER_LEVEL_CODES } from "@/assets/variables";

const baseurl = "/console/user-level";

export default {
    components: {},
    data: () => ({
        loading: false,
    }),
    computed: {
        items() {
            return [{ text: "전체", value: null }, ...Object.values(USER_LEVEL_CODES).filter(({ shows }) => shows)].map((item) => {
                let to = baseurl;
                if (item.value) to = `${baseurl}/${item.value}`;
                return { ...item, to };
            });
        },
    },
};
</script>

<style>
</style>