<template>
    <v-btn v-bind="{ ...$attrs, loading, disabled }" @click="commit"> {{ text }} </v-btn>
</template>

<script>
import api from "@/api";

import { initProduct, initProductOptionCriterion, initShopBulkUploadData, PRODUCT_OPTION_CRITERIA_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        result: initShopBulkUploadData(),
    }),
    computed: {
        disabled() {
            return this.parsed?.options?.length - 1 <= this.value?.options?.length;
        },
        text() {
            return this.disabled ? "처리완료" : "등록처리";
        },
        products() {
            return this.parsed.options.slice(1).reduce((products, option) => {
                let product = products.find(({ code }) => code == option.product);
                if (!product) {
                    product = {
                        code: option.product,
                        optionsConf: {
                            criteria: [],
                        },
                    };
                    products.push(product);
                }

                let items = option.name.split(" / ").map((item) => item.split(": ").map(this.$decode__productOptionName));
                items = items.map(([name, value]) => ({ name, value }));

                for (const item of items) {
                    let criterion = product.optionsConf.criteria.find((criterion) => criterion.name == item.name);
                    if (!criterion) {
                        criterion = {
                            type: item.name == "색상" ? PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value : PRODUCT_OPTION_CRITERIA_TYPES.OTHER.value,
                            name: item.name,
                            values: [],
                        };
                        product.optionsConf.criteria.push(criterion);
                    }
                    let value = criterion.values.find((value) => value.name == item.value);
                    if (!value) {
                        value = { name: item.value };
                        if (item.name == "색상") {
                            if (option.color?.img) value.img = option.color.img;
                            if (option.color?.hexa) value.hexa = option.color.hexa;
                        }
                        criterion.values.push(value);
                    }
                }

                return products;
            }, []);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        async commit() {
            if (this.loading) return;
            else this.$emit("setLoading", true);

            const { post, put } = api.console.shop.products.options;
            try {
                const products = await Promise.all(
                    this.products.map(async (form) => {
                        let { product } = await api.v1.shop.products.get({ code: form.code, headers: { mode: "code" } });
                        if (!product) return form;

                        product = initProduct(product);

                        for (const criterion of form.optionsConf.criteria) {
                            let item = product.optionsConf.criteria.find(({ type, name }) => type == criterion.type && name == criterion.name);
                            if (!item) {
                                item = criterion;
                                product.optionsConf.criteria.push(item);
                            }

                            for (const value of criterion?.values || []) {
                                let itemValue = item.values.find(({ name }) => name == value.name);
                                if (!itemValue) {
                                    itemValue = value;
                                    item.values.push(value);
                                }

                                if (criterion.type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value) {
                                    if (itemValue.img?.url != item.img) {
                                        itemValue.img = item.img || null;
                                    }

                                    if (!!itemValue.img && typeof itemValue.img == "string") {
                                        itemValue.img = (await api.console.files.post__bypass({ path: "shop-colors", _product: product._id, url: itemValue.img, isBypass: true }))?.file;
                                    }

                                    itemValue._img = itemValue.img?._id || null;
                                    itemValue.hexa = value.hexa || null;
                                }
                            }
                        }

                        return (await api.console.shop.products.put(product))?.product;
                    })
                );

                for (const parsedOption of [...this.parsed.options].slice(1)) {
                    try {
                        ////////////////////////////////////////////////////////
                        // POST, PUT document
                        ////////////////////////////////////////////////////////
                        let { product, options, ...form } = parsedOption;

                        // BIND product
                        if (!product) throw new Error("제품 상품코드를 입력하세요");
                        product = products.find(({ code }) => code == product);
                        if (!product?._id) throw new Error("제품 상품코드를 잘못 입력하셨습니다");
                        form._product = product._id;

                        let option = product.options.find((option) => option.code == form.code) || {};
                        if (option._id) form._id = option._id;
                        form.enabled = option?.enabled;
                        if (form.color?.img) {
                            form.color.img = product.optionsConf.criteria.find(({ type }) => type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value)?.values?.find?.(({ img }) => img?.url == form.color.img);
                        }

                        delete form.values;
                        delete form.rawData;
                        delete form.nameData;

                        option = (await (option?._id ? put : post)(form))?.option;

                        this.result.options.push(option);
                        this.emit();
                    } catch (error) {
                        this.result.options.push({ ...parsedOption, error });
                        this.emit();
                    }
                }
                for (let { _id, _options } of products) {
                    if (!_id) continue;

                    const options = this.result.options.filter(({ _product }) => _product == _id);
                    if (!options?.length) continue;

                    _options = _options.concat(...options.map(({ _id }) => _id));
                    _options = [...new Set(_options)];

                    await api.console.shop.products.put({ _id, _options });
                }
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
