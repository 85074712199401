<template>
    <v-row class="ml-0">
        <template v-for="item in items">
            <v-col :key="item.text" cols="12" sm="6" md="4" lg="3" class="pl-0 pt-0">
                <v-card outlined :class="{ primary: item.isActive }" :dark="item.isActive" @click="go(item)">
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="auto">
                                <v-icon>{{ item.icon }}</v-icon> {{ item.text }}
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <span class="font-weight-bold" :class="{ 'primary--text': !item.isActive }"> {{ item.count }} </span> 건
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

export default {
    props: {
        dashboard: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            const { ...query } = this.$route.query;

            query.page = 1;

            return [
                {
                    icon: "mdi-clipboard-arrow-up-outline",
                    text: "취소요청",
                    count: this.dashboard?.cancelRequest?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value,
                        },
                    },
                },
                {
                    icon: "mdi-clipboard-check-outline",
                    text: "취소완료",
                    count: this.dashboard?.cancelComplete?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value,
                        },
                    },
                },
                {
                    icon: "mdi-clipboard-remove-outline",
                    text: "취소거부",
                    count: this.dashboard?.cancelRejected?.format?.(),
                    to: {
                        query: {
                            ...query,
                            claimStatus: PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value,
                        },
                    },
                },
            ].map((item) => {
                const { claimStatus } = item.to.query;
                const sameClaimStatus = query.claimStatus == claimStatus;

                return { ...item, isActive: sameClaimStatus };
            });
        },
    },
    methods: {
        go({ to, isActive }) {
            if (isActive) {
                const { ...query } = this.$route.query;

                delete query.orderStatus;
                delete query.claimStatus;

                this.$router.push({ query });
            } else this.$router.push(to);
        },
    },
};
</script>

<style></style>
