<template>
    <v-layout justify-center>
        <v-responsive max-width="960" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="정회원 전환요청 목록" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.stateText`]="{ item, value }">
                    <edit-dialog-inquiry-state :value="item" v-on="{ updateItem }">
                        {{ value }}
                    </edit-dialog-inquiry-state>
                </template>
                <template #[`item.actions`]="{ item }">
                    <inquiry-view v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                        </template>
                    </inquiry-view>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import InquiryView from "@/components/console/inquiries/inquiry-view.vue";
import EditDialogInquiryState from "@/components/console/inquiries/edit-dialog-inquiry-state.vue";

const headers = [
    { divider: true, width: +80, text: "#", value: "index" },
    { divider: true, width: 120, text: "신청자", value: "user.name" },
    { divider: true, width: 150, text: "연락처", value: "phone" },
    { divider: true, width: 170, text: "신청일시", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { divider: true, width: 100, text: "상태", value: "stateText" },
    { width: +88, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ListHeading,
        InquiryView,
        EditDialogInquiryState,
    },
    data() {
        return {
            inquiries: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            loading: false,
        };
    },
    computed: {
        items() {
            return this.inquiries.map((item, index) => ({ ...item, index: index + 1 }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, inquiries } = await api.console.inquiries.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.inquiries = inquiries.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(inquiry) {
            try {
                inquiry = (await api.console.inquiries.put(inquiry))?.inquiry;
                this.updateItem(inquiry);
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(inquiry) {
            const go = confirm(`해당 문의를 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ inquiry }] = [await api.console.inquiries.delete(inquiry)];
                    this.inquiries = this.inquiries.filter(({ _id }) => _id !== inquiry._id);
                    alert(`해당 문의가 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(inquiry) {
            const index = this.inquiries.findIndex(({ _id }) => _id == inquiry._id);
            if (0 <= index) this.inquiries.splice(index, 1, inquiry);
            else {
                this.inquiries = [inquiry, ...this.inquiries];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
