<template>
    <v-btn small outlined color="primary" class="mr-2" v-bind="{ loading }" @click="put">수거완료</v-btn>
</template>

<script>
import api from "@/api";
import { PURCHASE_COLLECTION_STATUSES } from "@/assets/variables";

export default {
    props: {
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    methods: {
        async put() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error("수거완료 처리할 구매건을 선택해주세요");

                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, collectionStatus: PURCHASE_COLLECTION_STATUSES.COLLECTION_COMPLETE.value });
                }

                alert("수거완료 처리 되었습니다");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
