var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "360",
      "fullscreen": _vm.$vuetify.breakpoint.mobile
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', [_vm._v(" 색상 선택 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-5"
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('span', {
    staticClass: "d-inline-block white caption pa-1",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "left": "8px",
      "transform": "translateY(-50%)"
    }
  }, [_vm._v("색상")]), _c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": ""
    }
  }, [_c('v-responsive', {
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "aspect-ratio": 1 / 1,
      "height": "294"
    }
  }, [_c('v-color-picker', {
    attrs: {
      "hide-canvas": "",
      "hide-sliders": "",
      "hide-inputs": "",
      "show-swatches": "",
      "flat": "",
      "width": "100%",
      "swatches-max-height": "100%"
    },
    on: {
      "update:color": _vm.changeColor
    },
    model: {
      value: _vm.color,
      callback: function ($$v) {
        _vm.color = $$v;
      },
      expression: "color"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5",
      "color": "primary"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }