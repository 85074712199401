<template>
    <v-card flat outlined class="fill-height" style="overflow:hidden">
        <v-card-subtitle class="d-flex justify-space-between align-center font-weight-bold">
            구매확정내역 (회원본인)
        </v-card-subtitle>
        <v-divider />
        <v-data-table v-bind="{ items, headers }">
            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
            <template #[`item.product`]="{ item }"> {{ item?.product?.name }} - {{ item?.name }} </template>
            <template #footer>
                <v-divider /> <v-subheader class="justify-center"> 구매확정금액 합계: {{ purchasesPrice__total?.format?.() || 0 }}원 </v-subheader>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
const headers = [
    { text: "상품", value: "product", divider: true },
    { text: "수량", value: "amount", divider: true, formatter: (value) => value?.format?.() || value || 0, align: "end" },
    { text: "단가", value: "price", divider: true, formatter: (value) => value?.format?.() || value || 0, align: "end" },
    { text: "할인", value: "discountPrice", divider: true, formatter: (value) => value?.format?.() || value || 0, align: "end" },
    { text: "사용\n포인트", value: "pointPrice", divider: true, formatter: (value) => value?.format?.() || value || 0, align: "end", class: "white-space-pre-line" },
    { text: "합계", value: "purchasePrice__total", formatter: (value) => value?.format?.() || value || 0, align: "end" },
];
export default {
    props: {
        value: { type: Array, default: () => [] }, // purchases
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [...this.value];
        },
        purchasesPrice__total() {
            return this.value.reduce((sum, { purchasePrice__total }) => (sum += purchasePrice__total), 0);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
}
</style>
