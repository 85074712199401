var render = function render(){
  var _vm$$attrs, _vm$$attrs$props, _vm$$attrs2, _vm$$attrs2$props, _vm$$attrs3, _vm$$attrs3$props;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', _vm._b({}, 'v-row', (_vm$$attrs = _vm.$attrs) === null || _vm$$attrs === void 0 ? void 0 : (_vm$$attrs$props = _vm$$attrs.props) === null || _vm$$attrs$props === void 0 ? void 0 : _vm$$attrs$props.rowProps, false), [_c('v-col', _vm._b({}, 'v-col', (_vm$$attrs2 = _vm.$attrs) === null || _vm$$attrs2 === void 0 ? void 0 : (_vm$$attrs2$props = _vm$$attrs2.props) === null || _vm$$attrs2$props === void 0 ? void 0 : _vm$$attrs2$props.termProps, false), [_vm._v(_vm._s(_vm.term))]), _vm.divider ? _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }) : _vm._e(), _c('v-col', _vm._b({}, 'v-col', (_vm$$attrs3 = _vm.$attrs) === null || _vm$$attrs3 === void 0 ? void 0 : (_vm$$attrs3$props = _vm$$attrs3.props) === null || _vm$$attrs3$props === void 0 ? void 0 : _vm$$attrs3$props.dataProps, false), [_vm._t("default")], 2)], 1), !_vm.hideDivider ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }