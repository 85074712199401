<template>
    <v-btn small outlined color="primary" class="mr-2" v-bind="{ loading }" @click="put">반품으로 변경</v-btn>
</template>

<script>
import api from "@/api";
import { PURCHASE_CLAIM_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

export default {
    props: {
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    methods: {
        async put() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error("반품으로 변경 처리할 구매건을 선택해주세요");

                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, claimStatus: PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value, purchaseStatus: PURCHASE_PURCHASE_STATUSES.PURCHASE_PENDING.value, rejectedAt: null, canceledAt: null, returnedAt: null, exchangedAt: null, exchangeShippedAt: null });
                }

                alert("반품으로 변경 처리 되었습니다");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
