<template>
    <v-layout justify-center>
        <v-responsive max-width="1600" width="100%" height="100%">
            <list-heading title="회원 목록" v-model="showsSearch" showsFilterButton />
            <user-list-search v-bind="{ showsSearch }" />
            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <!-- <v-col cols="auto">
                    <user-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-card outlined>
                                <v-btn color="white primary--text" class="caption" v-bind="{ ...attrs, loading }" v-on="on">
                                    <v-layout align-center> <v-icon small class="mr-1">mdi-account-plus</v-icon> 회원생성 </v-layout>
                                </v-btn>
                            </v-card>
                        </template>
                    </user-form>
                </v-col> -->
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="users" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.index`]="{ item, value }">
                    <user-index-edit :value="item" v-on="{ update }">
                        {{ value }}
                    </user-index-edit>
                </template>
                <template #[`item.title`]="{ item, value }">
                    <edit-dialog-user-type :value="item" v-on="{ updateItem }"> {{ value }} </edit-dialog-user-type>
                </template>
                <template #[`item.console`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="console" v-bind="{ ...attrs_switch__console, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.article_user`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="article_user" v-bind="{ ...attrs_switch__console, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.company`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="company" v-bind="{ ...attrs_switch__console, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.kakao`]="{ item }">
                    <v-switch v-model="item.kakao" disabled v-bind="{ ...attrs_switch__console, item }"/>
                </template>
                <template #[`item.naver`]="{ item }">
                    <v-switch v-model="item.naver" disabled v-bind="{ ...attrs_switch__console, item }"/>
                </template>
                <template #[`item.category`]="{ item, value }">
                    <company-category-edit v-if="item.type === USER_TYPES.COMPANY.value" :value="item" v-on="{ update }">
                        {{ value ? value.map((key) => COMPANY_CATEGORY_TYPES[key]?.text).join(", ") : "-" }}
                    </company-category-edit>
                </template>
                <template #[`item.isWarning`]="{ item }">
                    <v-switch v-model="item.isWarning" v-bind="attrs_switch__console" @change="(isWarning) => update({ _id: item._id, isWarning })" />
                </template>
                <template #[`item.meta.isHot`]="{ item }">
                    <v-switch v-model="item.meta.isHot" v-bind="attrs_switch__console" @change="(isHot) => update({ _id: item._id, meta: { ...item?.meta, isHot } })" />
                </template>
                <template #[`item.isMainBannerShows`]="{ item }">
                    <v-switch v-model="item.isMainBannerShows" v-bind="attrs_switch__console" @change="(isMainBannerShows) => update({ _id: item._id, isMainBannerShows })" />
                </template>
                <template #[`item.isApproved`]="{ item }">
                    <span v-if="item.type === USER_TYPES.PERSON.value">-</span>
                    <v-switch v-else v-model="item.isApproved" v-bind="attrs_switch__console" @change="(isApproved) => update({ _id: item._id, isApproved })" />
                </template>
                <template #[`item.orderPrice`]="{ item }">
                    <span v-if="item.orderPrice">{{ item.orderPrice.format() }}</span>
                    <span v-else>-</span>
                </template>
                <template #[`item.meta.isReceiveAds`]="{ item }">
                    <v-switch v-model="item.meta.isReceiveAds" v-bind="attrs_switch__console" disabled />
                </template>
                <template #[`item.actions`]="{ item }">
                    <!-- <v-btn small text icon tile :to="`${$route.path}/${item._id}`"> -->
                    <v-btn small text icon tile :to="{ path: `${$route.path}/${item._id}`, query: { type: $route.query.type } }">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn small text icon tile @click="withdrawal(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch__console, initDataTableHeaders, USER_TYPES, COMPANY_CATEGORY_TYPES } from "@/assets/variables";

import UserIndexEdit from "@/components/console/users/user-index-edit.vue";
import CompanyCategoryEdit from "@/components/console/users/company-category-edit.vue";
import UserForm from "@/components/console/users/user-form.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserListSearch from "@/components/console/users/user-list-search.vue";
import UserScopeSwitch from "@/components/console/users/user-scope-switch.vue";
import EditDialogUserType from "@/components/console/users/edit-dialog-user-type.vue";

export default {
    components: {
        UserForm,
        ListHeading,
        UserListSearch,
        UserScopeSwitch,
        EditDialogUserType,
        CompanyCategoryEdit,
        UserIndexEdit,
    },
    data: () => ({
        users: [],
        USER_TYPES,

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        attrs_switch__console,
        COMPANY_CATEGORY_TYPES,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        headers() {
            return initDataTableHeaders([
                // { text: "유형", value: "type", formatter: (key) => USER_TYPES[key]?.text || "-", width: 100 },
                { text: "정렬", value: "index", width: 60, test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "아이디", value: "username", width: 100 },
                { text: "닉네임", value: "nickname", width: 100, test: (code) => code === USER_TYPES.PERSON.value },
                { text: "업체명", value: "companyName", width: 120, test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "이름", value: "name", width: 80 },
                { text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-", width: 160 },
                { text: "이메일", value: "email" },
                { text: "카카오", value: "kakao", width: 80, excel: (value) => value ? "O" : "X", test: (code) => code === USER_TYPES.PERSON.value },
                { text: "네이버", value: "naver", width: 80, excel: (value) => value ? "O" : "X", test: (code) => code === USER_TYPES.PERSON.value },
                { text: "카테고리", value: "category", width: 200, formatter: (value) => value.map((value)=>COMPANY_CATEGORY_TYPES[value].text).join(',') || "-", test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "추천인코드", value: "referrer", width: 100, align: "center", test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "회원등급", value: "level.name", width: 100, align: "center" },
                { text: "상품구매금액", value: "orderPrice", width: 120, align: "end", test: (code) => code === USER_TYPES.PERSON.value },
                { text: "소식/정보", value: "article_user", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80},
                { text: "승인여부", value: "isApproved", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80, test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "입점업체", value: "company", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80, test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "Hot 스토어", value: "meta.isHot", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80, test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "메인배너\n노출", value: "isMainBannerShows", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80, test: (code) => code === USER_TYPES.COMPANY.value },
                { text: "주의회원", value: "isWarning", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80 },
                { text: "광고동의", value: "meta.isReceiveAds", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: +80 },
                { text: "가입일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", width: 100 },
                { text: "", value: "actions", align: "center", width: 100 },
            ]).filter((header) => (header?.test ? header.test(this.$route.query.type) : true));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.users = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, users } = await api.console.users.gets({
                    headers: { skip, limit, mode: "without-default" },
                    params,
                });

                this.users = users;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            try {
                await api.console.users.put(user);
                this.updateItem(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async withdrawal(user) {
            try{
                if(confirm("해당 회원을 탈퇴처리시키겠습니까?")){
                    await api.console.users.put({
                        _id: user._id,
                        isWithdrawn: true,
                        withdrawalReason: "관리자 탈퇴처리",
                        withdrawalMessage: "관리자 탈퇴처리"
                    })
                    alert("탈퇴처리 되었습니다.");
                    this.search();
                } else return;
            } catch(error) {
                this.$handleError(error);
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params, sort } = this;
                var { users } = await api.console.users.gets({
                    params,
                });

                this.$excel(users, this.headers, "회원목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
