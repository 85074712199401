<template>
    <div class="px-4 py-3">
        <template v-if="this.value.isActive">
            <div class="font-weight-bold grey--text">{{ this.value.typeText }}</div>
            {{ this.value.value.format() }} {{ this.value?.unitSuffix }}
        </template>
        <template v-else> - </template>
    </div>
</template>

<script>
import { initUserLevelDiscount } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initUserLevelDiscount },
    },
};
</script>

<style></style>
