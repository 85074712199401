var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "pa-4 fill-height"
  }, [_c('v-sheet', {
    staticClass: "fill-height",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-file-btn', {
    attrs: {
      "accept": ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      "text": "",
      "block": "",
      "tile": "",
      "height": "100%"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.text) + " ")]), _c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-file-excel")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }