var render = function render(){
  var _vm$settlement, _vm$settlement2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "정산 상세"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('settlement-promoter', {
    attrs: {
      "value": (_vm$settlement = _vm.settlement) === null || _vm$settlement === void 0 ? void 0 : _vm$settlement.promoter
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('settlement-grade-card', {
    attrs: {
      "title": "당월 직급 정보",
      "value": (_vm$settlement2 = _vm.settlement) === null || _vm$settlement2 === void 0 ? void 0 : _vm$settlement2.grade
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('settlement-grade-card', {
    attrs: {
      "title": "익월 직급 예상",
      "value": _vm.grade__next
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('settlement-purchases-promoter', {
    attrs: {
      "value": _vm.purchases__promoter
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('settlement-purchases-promotee', {
    attrs: {
      "value": _vm.purchases__promotee
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }