var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "카테고리 설정"
    }
  }), _c('v-row', {
    staticClass: "mx-n2 my-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-toolbar', {
    attrs: {
      "color": "info lighten-1",
      "dense": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text subtitle-2"
  }, [_vm._v(" 카테고리 순서변경 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent",
      "fab": "",
      "dark": "",
      "small": "",
      "absolute": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.form = undefined;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', [_vm.categories.length ? [_c('v-draggable-treeview', {
    attrs: {
      "item-key": "_id",
      "children": "children",
      "group": "categories",
      "dense": ""
    },
    on: {
      "click": _vm.select,
      "input": _vm.update
    },
    model: {
      value: _vm.categories,
      callback: function ($$v) {
        _vm.categories = $$v;
      },
      expression: "categories"
    }
  })] : [_c('span', [_vm._v(" 등록된 카테고리가 없습니다. "), _c('br'), _vm._v(" 카테고리를 등록해주세요. ")])]], 2)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('shop-category-form', _vm._g(_vm._b({
    attrs: {
      "value": _vm.form
    }
  }, 'shop-category-form', {
    categories: _vm.categories,
    loading: _vm.loading
  }, false), {
    search: _vm.search,
    loading: function (bool) {
      return _vm.loading = bool;
    }
  }))], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "light": "",
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "100",
      "width": "5",
      "color": "primary",
      "indeterminate": ""
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }