<template>
    <v-btn v-bind="{ ...$attrs, loading, disabled }" @click="commit"> {{ text }} </v-btn>
</template>

<script>
import api from "@/api";

import { initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        result: initShopBulkUploadData(),
    }),
    computed: {
        disabled() {
            return this.parsed?.relateds?.length <= this.value?.relateds?.length;
        },
        text() {
            return this.disabled ? "처리완료" : "등록처리";
        },
        products() {
            return this.parsed.relateds.reduce((products, related) => {
                let product = products.find(({ code }) => code == related.parent);
                if (!product) {
                    product = { code: related.parent, relateds: [] };
                    products.push(product);
                }
                product.relateds.push(related);
                return products;
            }, []);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        async commit() {
            if (this.loading) return;
            else this.$emit("setLoading", true);

            const { post, put } = api.console.shop.products.relateds;
            try {
                for (let { relateds, ...product } of this.products) {
                    try {
                        product = this.result.products.find(({ code }) => code == product.code) || (await api.v1.shop.products.get({ code: product.code, headers: { mode: "code" } }))?.product;

                        if (!product) throw new Error("상품을 찾을 수 없습니다.");
                    } catch (error) {
                        this.result.relateds.push(...relateds.map((item) => ({ ...item, errors: [...(item.errors || []), error] })));
                        continue;
                    }

                    for (let [index, related] of relateds.entries()) {
                        try {
                            ////////////////////////////////////////////////////////
                            // POST, PUT document
                            ////////////////////////////////////////////////////////
                            let { ...form } = related;
                            related = product.relateds.find(({ code }) => code == form.target) || related;

                            if (related?._id) {
                                form._id = related._id;
                                form._product = related._product;
                                // form._option = related._option;
                                // form._supply = related._supply;
                            } else {
                                let product = (await api.v1.shop.products.get({ code: form.target, headers: { mode: "code" } }))?.product;
                                if (product) form._product = product._id;
                                else throw new Error("필수상품을 찾을 수 없습니다.");
                            }

                            delete form.rawData;
                            delete form.nameData;

                            related = (await (form?._id ? put : post)(form))?.related;

                            this.result.relateds.push(related);
                            relateds[index] = related;

                            this.emit();
                        } catch (error) {
                            this.result.relateds.push({ ...related, errors: [...(related.errors || []), error] });
                            this.emit();
                        }
                    }

                    try {
                        await api.console.shop.products.put({
                            _id: product._id,
                            _relateds: [...new Set([...product._relateds, ...relateds.map(({ _id }) => _id)])].filter((item) => item),
                        });
                    } catch (error) {
                        this.result.relateds.push(this.result.relateds.slice(-relateds.length).map((item) => ({ ...item, errors: [...(item.errors || []), error] })));
                    }
                }
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
