var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-0"
  }, [_vm._l(_vm.limit, function (depth, index) {
    return [_c('v-col', {
      key: index,
      staticClass: "py-3 py-sm-0",
      attrs: {
        "cols": "12",
        "sm": 12 / _vm.limit
      }
    }, [_c('v-select', _vm._b({
      on: {
        "input": _vm.emit
      }
    }, 'v-select', Object.assign({}, _vm.$attrs, {
      label: `${depth}차 카테고리`,
      value: _vm.select(depth),
      items: _vm.items(depth),
      itemText: 'name',
      itemValue: '_id',
      itemDisabled: _vm.itemDisabled,
      placeholder: _vm.placeholder
    }), false))], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }