<template>
    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
        <v-row v-bind="$attrs?.props?.rowProps">
            <v-col v-bind="$attrs?.props?.termProps">{{ term }}</v-col>
            <v-divider vertical v-if="divider" />
            <v-col v-bind="$attrs?.props?.dataProps"> <slot /> </v-col>
        </v-row>
        <v-divider v-if="!hideDivider" />
    </v-sheet>
</template>

<script>
export default {
    props: {
        term: { type: String },
        divider: { type: Boolean, default: false },
        hideDivider: { type: Boolean, default: false },
    },
};
</script>
