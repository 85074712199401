<template>
    <v-row class="ml-0">
        <template v-for="item in items">
            <v-col :key="item.text" cols="12" sm="6" md="4" lg="3" class="pl-0 pt-0">
                <v-card outlined :class="{ primary: item.isActive }" :dark="item.isActive" @click="go(item)">
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="auto">
                                <v-icon>{{ item.icon }}</v-icon> {{ item.text }}
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <span class="font-weight-bold" :class="{ 'primary--text': !item.isActive }"> {{ item.count }} </span> 건
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES } from "@/assets/variables";

export default {
    props: {
        dashboard: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            const { ...query } = this.$route.query;

            query.page = 1;

            return [
                {
                    icon: "mdi-truck-fast-outline",
                    text: "배송중",
                    count: this.dashboard?.shippingConfirm?.format?.(),
                    to: {
                        query: {
                            ...query,
                            orderStatus: PURCHASE_ORDER_STATUSES.SHIPPING_CONFIRM.value,
                            claimStatus: [null, PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value, PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value],
                        },
                    },
                },
                {
                    icon: "mdi-truck-check-outline",
                    text: "배송완료",
                    count: this.dashboard?.deliveryComplete?.format?.(),
                    to: {
                        query: {
                            ...query,
                            orderStatus: PURCHASE_ORDER_STATUSES.DELIVERY_COMPLETE.value,
                            claimStatus: [null, PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value, PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value],
                        },
                    },
                },
            ].map((item) => {
                const { orderStatus, claimStatus } = item.to.query;
                const sameOrderStatus = query.orderStatus == orderStatus;
                const sameClaimStatus = query.claimStatus && Array.isArray(query.claimStatus) && !claimStatus.some((value) => !query.claimStatus.includes(value));

                return { ...item, isActive: sameOrderStatus && sameClaimStatus };
            });
        },
    },
    methods: {
        go({ to, isActive }) {
            if (isActive) {
                const { ...query } = this.$route.query;

                delete query.orderStatus;
                delete query.claimStatus;

                this.$router.push({ query });
            } else this.$router.push(to);
        },
    },
};
</script>

<style></style>
