var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "fill-height",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v("회원 정보")]), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "margin-bottom": "-1px"
    }
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view"
    },
    scopedSlots: _vm._u([{
      key: "은행계좌",
      fn: function () {
        var _vm$value, _vm$value$bank, _vm$value2, _vm$value2$bank, _vm$value3, _vm$value3$bank;
        return [_vm._v(" " + _vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$bank = _vm$value.bank) === null || _vm$value$bank === void 0 ? void 0 : _vm$value$bank.text) + " / " + _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$bank = _vm$value2.bank) === null || _vm$value2$bank === void 0 ? void 0 : _vm$value2$bank.accountNumber) + " / " + _vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$bank = _vm$value3.bank) === null || _vm$value3$bank === void 0 ? void 0 : _vm$value3$bank.accountHolder) + " ")];
      },
      proxy: true
    }])
  }, 'vertical-form-table', {
    value: _vm.value,
    items: _vm.items
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }