<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="정산 상세" />
            <v-row>
                <v-col cols="6">
                    <settlement-promoter :value="settlement?.promoter" />
                </v-col>
                <v-col cols="3">
                    <settlement-grade-card title="당월 직급 정보" :value="settlement?.grade" />
                </v-col>
                <v-col cols="3">
                    <settlement-grade-card title="익월 직급 예상" :value="grade__next" />
                </v-col>
                <v-col cols="6">
                    <settlement-purchases-promoter :value="purchases__promoter" />
                </v-col>
                <v-col cols="6">
                    <settlement-purchases-promotee :value="purchases__promotee" />
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import SettlementPromoter from "@/components/console/settlements/view/settlement-promoter.vue";
import SettlementGradeCard from "@/components/console/settlements/view/settlement-grade-card.vue";
import SettlementPurchasesPromoter from "@/components/console/settlements/view/settlement-purchases-promoter.vue";
import SettlementPurchasesPromotee from "@/components/console/settlements/view/settlement-purchases-promotee.vue";

export default {
    components: {
        ListHeading,
        SettlementPromoter,
        SettlementGradeCard,
        SettlementPurchasesPromoter,
        SettlementPurchasesPromotee,
    },
    props: {
        _settlement: { type: String },
    },
    data: () => ({
        loading: false,

        settlement: undefined,

        settlement__prev: undefined,
        settlement__next: undefined,

        purchases__promoter: [],
        purchases__promotee: [],

        grade__next: undefined,
    }),
    mounted() {
        this.init();
    },
    watch: {
        _settlement() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.settlement = undefined;
            this.purchases__promoter = [];
            this.purchases__promotee = [];
            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                var { settlement, settlement__prev, settlement__next, purchases__promoter, purchases__promotee, grade__next } = await api.console.settlements.get({ _id: this._settlement });
                this.settlement = settlement;
                this.settlement__prev = settlement__prev;
                this.settlement__next = settlement__next;
                this.purchases__promoter = purchases__promoter;
                this.purchases__promotee = purchases__promotee;

                this.grade__next = grade__next;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
