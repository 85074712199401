var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "fixed-header": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.label`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('image-popup', {
          attrs: {
            "src": value,
            "attrs_avatar": {
              size: 40,
              tile: true
            }
          }
        })];
      }
    }, {
      key: `item.thumb`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('image-popup', {
          attrs: {
            "src": value,
            "attrs_avatar": {
              size: 40,
              tile: true
            }
          }
        })];
      }
    }, {
      key: `item.image`,
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_c('image-popup', {
          attrs: {
            "src": value,
            "attrs_avatar": {
              size: 40,
              tile: true
            }
          }
        })];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }