var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n1"
  }, [_vm.tilesOnly ? [_vm._l(_vm.images, function (image) {
    return [_c('v-col', _vm._b({
      key: image,
      staticClass: "pa-1"
    }, 'v-col', {
      cols: _vm.cols
    }, false), [_c('image-tiles-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on,
            setIndex = _ref.setIndex;
          return [_c('v-card', {
            attrs: {
              "outlined": "",
              "flat": "",
              "color": "grey"
            },
            on: {
              "click": function ($event) {
                return setIndex(image);
              }
            }
          }, [_c('v-img', _vm._g(_vm._b({
            attrs: {
              "src": image,
              "aspect-ratio": 1 / 1,
              "width": "100%"
            }
          }, 'v-img', attrs, false), on))], 1)];
        }
      }], null, true)
    }, 'image-tiles-dialog', {
      images: _vm.images
    }, false))], 1)];
  })] : [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs-items', {
    attrs: {
      "value": _vm.selectedIndex
    }
  }, [_vm._l(_vm.images, function (image, index) {
    return [_c('v-tab-item', {
      key: image,
      attrs: {
        "value": index
      }
    }, [_c('image-tiles-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var attrs = _ref2.attrs,
            on = _ref2.on,
            setIndex = _ref2.setIndex;
          return [_c('v-img', _vm._g(_vm._b({
            staticClass: "grey",
            attrs: {
              "src": image,
              "aspect-ratio": 1 / 1,
              "width": "100%"
            },
            on: {
              "click": function ($event) {
                return setIndex(image);
              }
            }
          }, 'v-img', attrs, false), on))];
        }
      }], null, true)
    }, 'image-tiles-dialog', {
      images: _vm.images
    }, false))], 1)];
  })], 2)], 1), _c('v-col', {
    staticClass: "pa-2",
    class: {
      'order-first': _vm.reverse
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.images, function (image, index) {
    return [_c('v-col', _vm._b({
      key: image,
      staticClass: "pa-1",
      staticStyle: {
        "position": "relative"
      },
      on: {
        "mouseenter": function ($event) {
          return _vm.setSelectedIndex(image);
        }
      }
    }, 'v-col', {
      cols: _vm.cols
    }, false), [_c('v-fade-transition', [_c('v-overlay', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.selectedIndex == index,
        expression: "selectedIndex == index"
      }],
      attrs: {
        "absolute": "",
        "z-index": "0",
        "color": "black",
        "opacity": "1"
      }
    })], 1), _c('v-img', {
      attrs: {
        "src": image,
        "aspect-ratio": 1 / 1,
        "width": "100%"
      }
    })], 1)];
  })], 2)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }