<template>
    <v-layout column fill-height>
        <v-data-table v-bind="{ headers, items, loading }" hide-default-footer disable-filtering disable-sort :items-per-page="-1">
            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

            <template #[`item.errors`]="{ header, item, value }">
                <tab-commit-errors v-bind="{ item }">
                    <template #activator="{ attrs, on }">
                        <v-card v-bind="attrs" v-on="on" flat tile color="transparent" class="fill-height px-4 py-3">
                            {{ header.formatter.bind(item)(value, item) }}
                        </v-card>
                    </template>
                </tab-commit-errors>
            </template>

            <template #[`item.commit`]="{ item }">
                <template v-if="item.key == 'brands'">
                    <commit-brands v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-brands" />
                </template>
                <template v-if="item.key == 'bundleds'">
                    <commit-bundleds v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-bundleds" />
                </template>
                <template v-if="item.key == 'categories'">
                    <commit-categories v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-categories" />
                </template>
                <template v-if="item.key == 'choicees'">
                    <commit-choicees v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-choicees" />
                </template>
                <template v-if="item.key == 'options'">
                    <commit-options v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-options" />
                </template>
                <template v-if="item.key == 'products'">
                    <commit-products v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-products" />
                </template>
                <template v-if="item.key == 'relateds'">
                    <commit-relateds v-model="result" text block tile height="48" v-bind="{ parsed, loading }" v-on="{ setLoading }" @input="emit" ref="commit-relateds" />
                </template>
            </template>
            <template #foot>
                <tr>
                    <td :colspan="headers.length"><v-divider /></td>
                </tr>
                <tr>
                    <td :colspan="headers.length - 1"></td>
                    <td>
                        <v-btn text block height="48" color="primary" :disabled="commitDisabled" v-bind="{ loading }" @click="commit"> 전체등록 </v-btn>
                    </td>
                </tr>
                <tr>
                    <td :colspan="headers.length"><v-divider /></td>
                </tr>
            </template>
        </v-data-table>
        <v-fade-transition>
            <v-overlay absolute light color="white" class="black--text" v-show="!file">
                <v-card-title> 파일을 먼저 등록해주세요 </v-card-title>
            </v-overlay>
        </v-fade-transition>
    </v-layout>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData, SHOP_BULK_UPLOAD_KEYS } from "@/assets/variables";

import CommitBrands from "./commit/commit-brands.vue";
import CommitBundleds from "./commit/commit-bundleds.vue";
import CommitCategories from "./commit/commit-categories.vue";
import CommitProducts from "./commit/commit-products.vue";
import CommitOptions from "./commit/commit-options.vue";
import CommitChoicees from "./commit/commit-choicees.vue";
import CommitRelateds from "./commit/commit-relateds.vue";
import TabCommitErrors from "./tab-commit-errors.vue";

const headers = initDataTableHeaders([
    { value: "name", text: "등록유형" },
    { value: "valueLength", text: "등록대상" },
    { value: "result", text: "처리완료", formatter: (value) => value?.length || 0 },
    { value: "errors", text: "처리오류", formatter: (value) => value?.length || 0, cellClass: "pa-0" },
    { value: "commit", text: "", cellClass: "pa-0" },
]);

export default {
    components: {
        CommitBrands,
        CommitBundleds,
        CommitCategories,
        CommitProducts,
        CommitOptions,
        CommitChoicees,
        CommitRelateds,
        TabCommitErrors,
    },
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
    },
    data: () => ({
        result: initShopBulkUploadData(),

        headers,

        loading: false,
    }),
    computed: {
        items() {
            return Object.keys(this.parsed).reduce((items, key) => {
                const value = this.parsed[key];
                if (value?.length) {
                    items.push({
                        key,
                        name: SHOP_BULK_UPLOAD_KEYS[key]?.text,
                        value,
                        valueLength: value.length - (key == "options" ? 1 : 0),
                        result: this.result[key],
                        errors: (this.result[key] || []).filter(({ errors }) => !!errors?.length),
                    });
                }
                return items;
            }, []);
        },
        commitDisabled() {
            return !this.items.some(({ value, result }) => value.length != result.length);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        setLoading(loading) {
            this.loading = loading;
        },
        async commit() {
            for (const { key } of this.items) {
                const target = this.$refs[`commit-${key}`];
                if (!target || target?.disabled) continue;

                await target.commit();
            }
        },
    },
};
</script>

<style></style>
