<template>
    <v-row align="center">
        <v-col cols="12" class="py-0">
            <v-row align="center">
                <v-col>
                    <v-text-field v-model="postcode" v-bind="{ ...$attrs, dense }" :label="label ? `${label} 우편번호` : '우편번호'" hide-details readonly :class="$attrs.className" @click="search" />
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btnAttrs, dense }" :x-large="dense == false ? true : false" :class="btnAttrs?.className" @click="search">검색</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-text-field v-model="address1" v-bind="{ ...$attrs, dense }" :label="label ? `${label} 기본주소` : '기본주소'" hide-details readonly :class="$attrs.className" @click="search" />
        </v-col>
        <v-col cols="12">
            <v-text-field v-model="address2" v-bind="{ ...$attrs, rules, dense }" :label="label ? `${label} 상세주소` : '상세주소'" hide-details="auto" :class="$attrs.className" />
            <daum-postcode ref="daum-postcode" @change="processAddressData" />
        </v-col>
        <get-geolocation v-if="withGeolocation" v-model="geolocation" :address="address1" @input="emit" />
    </v-row>
</template>

<script>
import { initGeolocation } from "@/assets/variables";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import GetGeolocation from "./get-geolocation.vue";

export default {
    components: {
        DaumPostcode,
        GetGeolocation,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // user
        label: { type: String, default: "" },
        dense: { type: Boolean },
        btnAttrs: { type: Object, default: () => ({}) }, // user
        withGeolocation: { type: Boolean, default: false },
    },
    data: () => ({
        postcode: null,
        address1: null,
        address2: null,
        geolocation: initGeolocation({ coordinates: [] }),
    }),
    computed: {
        rules() {
            return [() => (!!this.postcode && !!this.address1 && !!this.address2) || "주소를 입력해주세요"];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        postcode() {
            this.emit();
        },
        address1() {
            this.emit();
        },
        address2() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.postcode = this.value?.postcode || null;
            this.address1 = this.value?.address1 || null;
            this.address2 = this.value?.address2 || null;
            this.geolocation = this.value?.geolocation || initGeolocation({ coordinates: [] });
        },

        emit() {
            const { postcode, address1, address2 } = this;

            const form = { ...this.value, postcode, address1, address2 };
            if (this.withGeolocation) form.geolocation = initGeolocation(this.geolocation);

            this.$emit("input", form);
        },

        search() {
            this.$refs["daum-postcode"]?.open?.();
        },

        processAddressData({ postcode, address }) {
            this.postcode = postcode;
            this.address1 = address;
        },
    },
};
</script>

<style></style>
