var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "product-bundleds-table",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": ""
    },
    scopedSlots: _vm._u([{
      key: `header.variation.spec`,
      fn: function () {
        return [_c('v-row', {
          staticClass: "fill-height",
          attrs: {
            "no-gutters": "",
            "align": "center"
          }
        }, [_vm._l(_vm.headers__spec, function (text, index) {
          return [_c('v-col', {
            key: `input-${index}`,
            staticClass: "px-3"
          }, [_vm._v(" " + _vm._s(text) + " ")]), index != _vm.form.variation.spec.length - 1 ? _c('v-col', {
            key: `divider-${index}`,
            attrs: {
              "cols": "auto",
              "align-self": "stretch"
            }
          }, [_c('v-divider', {
            attrs: {
              "vertical": ""
            }
          })], 1) : _vm._e()];
        })], 2)];
      },
      proxy: true
    }, {
      key: `item.variation.head`,
      fn: function (_ref) {
        var item = _ref.item,
          value = _ref.value;
        return [_c('autocomplete-product', _vm._b({
          staticClass: "caption",
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([item.variation.head ? {
            key: "append",
            fn: function () {
              return [_c('v-btn', {
                attrs: {
                  "x-small": "",
                  "plain": "",
                  "icon": "",
                  "text": "",
                  "to": `/console/shop/products/${value === null || value === void 0 ? void 0 : value._id}`
                }
              }, [_c('v-icon', [_vm._v("mdi-link")])], 1)];
            },
            proxy: true
          } : null], null, true),
          model: {
            value: item.variation.head,
            callback: function ($$v) {
              _vm.$set(item.variation, "head", $$v);
            },
            expression: "item.variation.head"
          }
        }, 'autocomplete-product', _vm.props__head, false))];
      }
    }, {
      key: `item.variation.spec`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_vm._l(_vm.headers__spec, function (_, index) {
          return [_c('v-col', {
            key: `input-${index}`
          }, [_c('v-text-field', _vm._b({
            staticClass: "caption",
            on: {
              "input": _vm.emit
            },
            model: {
              value: item.variation.spec[index],
              callback: function ($$v) {
                _vm.$set(item.variation.spec, index, $$v);
              },
              expression: "item.variation.spec[index]"
            }
          }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1), index != _vm.form.variation.spec.length - 1 ? _c('v-col', {
            key: `divider-${index}`,
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-divider', {
            attrs: {
              "vertical": ""
            }
          })], 1) : _vm._e()];
        })], 2)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }