<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <template v-for="{ term, desc } in items">
                    <v-col cols="12" class="py-3 px-4" :key="term">
                        <div class="font-weight-bold grey--text">{{ term }}</div>
                        {{ desc }}
                    </v-col>
                </template>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { initIssue } from "@/assets/variables";

export default {
    props: {
        issue: { type: Object, default: initIssue },
    },
    computed: {
        isActiveText() {
            return this.issue.isActive ? "발급가능" : "발급불가";
        },
        eventText() {
            let text = "";
            const { typeText, codeText } = this.issue.event;
            if (typeText) text += typeText;
            if (codeText) text += ` - ${codeText}`;
            const { issuesAt } = this.issue;
            if (issuesAt) text += `\n${issuesAt.toDate()}`;
            return text || "시점없음";
        },
        roundText() {
            let text = "";
            const { typeText, includeds, excludeds } = this.issue.round;
            if (typeText) text += typeText;
            if (includeds?.length) text += `\n포함회차: ${includeds.join(", ")}`;
            if (excludeds?.length) text += `\n포함회차: ${excludeds.join(", ")}`;
            return text;
        },
        items() {
            return [
                { term: "발급방식", desc: this.issue.typeText },
                { term: "발급시점", desc: this.eventText },
                { term: "발급회차", desc: this.roundText },
            ];
        },
    },
};
</script>

<style>
</style>