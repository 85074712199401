var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.form.fixedIndex,
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.form, "fixedIndex", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.form, "fixedIndex", $event);
      },
      "open": _vm.sync
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-sheet', {
          attrs: {
            "width": "240"
          }
        }, [_c('v-currency-field', {
          attrs: {
            "hide-details": "",
            "clearable": "",
            "disabled": !_vm.value.isFixed
          },
          model: {
            value: _vm.form.fixedIndex,
            callback: function ($$v) {
              _vm.$set(_vm.form, "fixedIndex", $$v);
            },
            expression: "form.fixedIndex"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }