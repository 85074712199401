<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="메인 광고배너 목록">
                <template #add-button>
                    <banner-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </banner-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.index`]="{ item, value }">
                    <banner-index-edit :value="item" v-on="{ update }">
                        {{ value }}
                    </banner-index-edit>
                </template>
                <template #[`item.image`]="{ value }"> <image-popup size="40" :src="value?.src" /> </template>
                <template #[`item.title`]="{ item }">
                    <v-row @click="$set(item, 'show', !item.show)">
                        <v-col cols="auto" class="text-start">{{ item.title }}</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                            <v-icon v-else>mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row v-show="item.show">
                            <v-col cols="12" class="text-start">
                                <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </template>
                <template #[`item.url`]="{ item }">
                    <a v-if="item?.isOuterLink" :href="item.url" target="_blank">{{item.url}}</a>
                    <router-link v-else :to="item.url" target="_blank">
                        <span>{{ item.url }}</span>
                    </router-link>
                </template>
                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch__console" @change="(shows) => update({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <banner-form v-bind="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </banner-form>

                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch__console } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BannerForm from "@/components/console/banners/advertisement/banner-form.vue";
import BannerIndexEdit from "@/components/console/banners/advertisement/banner-index-edit.vue";

const headers = [
    { divider: true, width: 50, text: "#", value: "index", cellClass: "pa-0", align: "center" },
    { divider: true, width: +80, text: "배너", value: "image", align: "center" },
    { divider: true, width: 450, text: "제목 / 내용", value: "title" },
    { divider: true, width: 70, text: "URL", value: "url", align: "center" },
    { divider: true, width: 70, text: "표시", value: "shows", align: "center" },
    { divider: true, width: 120, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: 80, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ImagePopup,
        ListHeading,
        BannerForm,
        BannerIndexEdit,
    },
    data() {
        return {
            advertisements: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            attrs_switch__console,
            loading: false,
        };
    },
    computed: {
        items() {
            return [...this.advertisements];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, advertisements } = await api.console.bannerAdvertisements.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.advertisements = advertisements;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(advertisement) {
            try {
                advertisement = (await api.console.bannerAdvertisements.put(advertisement))?.advertisement;
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(advertisement) {
            const go = confirm(`해당 광고배너를 삭제하시겠습니까?`);
            if (go) {
                try {
                    advertisement = await api.console.bannerAdvertisements.delete(advertisement);
                    alert(`해당광고가 삭제되었습니다.`);
                    this.search();
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
