var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({}, 'v-card', _vm.$attrs, false), [_c('v-card-text', {
    staticClass: "caption"
  }, [_c('v-row', {
    staticClass: "ma-n4",
    staticStyle: {
      "word-break": "keep-all"
    }
  }, [!_vm.items.length ? [_c('v-col', [_vm._v(" 제한없음 ")])] : _vm._e(), _vm._l(_vm.items, function (_ref) {
    var term = _ref.term,
      desc = _ref.desc,
      prefix = _ref.prefix,
      suffix = _ref.suffix;
    return [_c('v-col', {
      key: term,
      staticClass: "py-3 px-4",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "font-weight-bold grey--text"
    }, [_vm._v(_vm._s(term))]), _vm._v(" " + _vm._s(prefix || "") + " " + _vm._s(desc) + " " + _vm._s(suffix || "") + " ")])];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }