var render = function render(){
  var _vm$item, _vm$item$errors, _value, _value2, _value3, _value4, _value5, _value6, _value7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "disabled": !((_vm$item = _vm.item) !== null && _vm$item !== void 0 && (_vm$item$errors = _vm$item.errors) !== null && _vm$item$errors !== void 0 && _vm$item$errors.length),
      "max-width": "1440"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_vm.item.key == 'brands' ? [_c('verify-brands', {
    attrs: {
      "value": (_value = {}, _value[_vm.item.key] = _vm.item.errors, _value),
      "isError": ""
    }
  })] : _vm._e(), _vm.item.key == 'bundleds' ? [_c('verify-bundleds', {
    attrs: {
      "value": (_value2 = {}, _value2[_vm.item.key] = _vm.item.errors, _value2),
      "isError": ""
    }
  })] : _vm._e(), _vm.item.key == 'categories' ? [_c('verify-categories', {
    attrs: {
      "value": (_value3 = {}, _value3[_vm.item.key] = _vm.item.errors, _value3),
      "isError": ""
    }
  })] : _vm._e(), _vm.item.key == 'choicees' ? [_c('verify-choicees', {
    attrs: {
      "value": (_value4 = {}, _value4[_vm.item.key] = _vm.item.errors, _value4),
      "isError": ""
    }
  })] : _vm._e(), _vm.item.key == 'options' ? [_c('verify-options', {
    attrs: {
      "value": (_value5 = {}, _value5[_vm.item.key] = _vm.item.errors, _value5),
      "isError": ""
    }
  })] : _vm._e(), _vm.item.key == 'products' ? [_c('verify-products', {
    attrs: {
      "value": (_value6 = {}, _value6[_vm.item.key] = _vm.item.errors, _value6),
      "isError": ""
    }
  })] : _vm._e(), _vm.item.key == 'relateds' ? [_c('verify-relateds', {
    attrs: {
      "value": (_value7 = {}, _value7[_vm.item.key] = _vm.item.errors, _value7),
      "isError": ""
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }