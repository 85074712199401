<template>
    <v-card flat outlined class="fill-height" style="overflow:hidden">
        <v-card-subtitle class="font-weight-bold">{{ title }}</v-card-subtitle>
        <v-card-text class="pa-0" style="margin-bottom: -1px;">
            <vertical-form-table v-bind="{ value, items }" mode="view">
                <template #기준> {{ criteria.join?.("\n") || "기준없음" }} </template>
                <template #혜택> {{ benefits.join?.("\n") }} </template>
            </vertical-form-table>
        </v-card-text>
    </v-card>
</template>

<script>
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";
import { GRADE_BENEFITS_TYPES, GRADE_CRITERIA_TYPES } from "@/assets/variables";
export default {
    components: {
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // grade
        title: { type: String },
    },
    computed: {
        items() {
            return [
                {
                    term: "Lv.",
                    data: `${this.value?.value || 0}`,
                },
                {
                    term: "호칭",
                    key: "title",
                },
                {
                    term: "기준",
                    key: "critieria",
                },
                {
                    term: "혜택",
                },
            ].map((item) => ({ ...item, headerProps: { class: "grey lighten-5 font-weight-bold grey--text text--darken-2" } }));
        },
        criteria() {
            const activeCriteria = this.value?.criteria?.filter?.(({ isActive }) => isActive) || [];
            return activeCriteria.map(({ type, value }) => {
                let text = GRADE_CRITERIA_TYPES[type]?.text || "";
                if (value) text += ` - ${value?.format?.()}원 이상`;
                return text.trim();
            });
        },
        benefits() {
            const activeBenefits = this.value?.benefits?.filter?.(({ isActive }) => isActive) || [];
            return activeBenefits.map(({ type, value }) => {
                let text = GRADE_BENEFITS_TYPES[type]?.text || "";
                if (value) text += ` (${value?.format?.()}%)`;
                return text.trim();
            });
        },
    },
};
</script>

<style></style>
