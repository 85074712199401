<template>
    <v-card v-bind="$attrs">
        <v-list-item :draggable="false" v-show="showsUser" class="mt-1 mb-n4">
            <v-list-item-avatar color="grey" size="30" class="mr-2">
                <v-icon small color="white">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="subtitle-2 text-truncate">
                    <b> {{ user.username || "-" }} </b> 님
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- <v-card-subtitle class="caption font-weight-bold">
            제목:
            {{ item.subject || "-" }}
        </v-card-subtitle> -->
        <v-card-subtitle class="caption font-weight-bold">
            <v-rating half-increments readonly dense x-small color="grey darken-4" background-color="grey darken-4" :value="review?.star" />
        </v-card-subtitle>
        <v-card-text class="caption white-space-pre-line" style="flex: 1 0 auto">
            {{ review?.content }}
            <template v-if="review?.images?.length">
                <image-tiles :images="(review?.images || []).map(({ src }) => src)" tilesOnly class="pt-4" />
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import { initShopReview, initUser } from "@/assets/variables";

import ImageTiles from "@/components/dumb/image-tiles/image-tiles.vue";

export default {
    components: {
        ImageTiles,
    },
    props: {
        review: { type: Object, default: initShopReview },
        showsUser: { type: Boolean, default: false },
    },
    computed: {
        user() {
            return initUser(this.review?.user);
        },
    },
};
</script>

<style>
</style>