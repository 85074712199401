var render = function render(){
  var _vm$product, _vm$product$thumb, _vm$product$category;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', _vm._b({
    attrs: {
      "draggable": false
    }
  }, 'v-list-item', {
    to: _vm.to,
    target: _vm.target
  }, false), [_c('v-list-item-avatar', {
    staticClass: "my-0 mr-1 ml-n1"
  }, [_c('image-popup', {
    attrs: {
      "src": (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$thumb = _vm$product.thumb) === null || _vm$product$thumb === void 0 ? void 0 : _vm$product$thumb.url,
      "attrs_avatar": {
        size: '30',
        rounded: true
      }
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "caption text-truncate"
  }, [_c('span', [_vm._v(" " + _vm._s((_vm$product$category = _vm.product.category) === null || _vm$product$category === void 0 ? void 0 : _vm$product$category.name) + " ")])]), _c('v-list-item-title', {
    staticClass: "caption text-truncate"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.product.name || _vm.placeholder || "-") + " ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }