<template>
    <v-btn v-bind="{ ...$attrs, loading, disabled }" @click="commit"> {{ text }} </v-btn>
</template>

<script>
import api from "@/api";

import { initProductOptionsConf, initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        result: initShopBulkUploadData(),

        brands: [],
        categories: [],
    }),
    computed: {
        disabled() {
            return this.parsed?.products?.length <= this.value?.products?.length;
        },
        text() {
            return this.disabled ? "처리완료" : "등록처리";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        async commit() {
            if (this.loading) return;
            else this.$emit("setLoading", true);

            const { post, put } = api.console.shop.products;
            try {
                this.brands = (await api.console.shop.brands.gets())?.brands || [];
                this.categories = (await api.console.shop.categories.gets())?.categories || [];

                for (const parsedProduct of this.parsed.products) {
                    try {
                        ////////////////////////////////////////////////////////
                        // POST, PUT document
                        ////////////////////////////////////////////////////////
                        let { category, brand, thumb, images, ...form } = parsedProduct;

                        let { product } = await api.v1.shop.products.get({
                            code: form.code,
                            headers: { mode: "code" },
                        });
                        if (product) form = { ...product, ...form, optionsConf: initProductOptionsConf({ ...(product?.optionsConf || {}), ...(form?.optionsConf || {}) }) };

                        delete form.thumb;
                        delete form.images;
                        delete form.rawData;
                        delete form.nameData;

                        // SET _category
                        category = !!category ? (category == form.category?.code ? form.category : this.categories.find(({ code }) => category == code)) : null;
                        form._category = category?._id || null;

                        // SET _brand
                        brand = !!brand ? (brand == form.brand?.code ? form.brand : this.brands.find(({ code }) => brand == code)) : null;
                        form._brand = brand?._id || null;

                        product = (await (product ? put : post)(form))?.product;

                        ////////////////////////////////////////////////////////
                        // POST, PUT resources
                        ////////////////////////////////////////////////////////

                        // thumb
                        thumb = !!thumb ? (thumb == product.thumb?.url ? product.thumb : (await api.console.files.post__bypass({ _product: product._id, url: thumb, isBypass: true }))?.file) : null;
                        const _thumb = thumb?._id || null;

                        // images
                        images = await Promise.all((images || []).map(async (image) => product.images.find((item) => item?.url == image) ?? (await api.console.files.post__bypass({ _product: product._id, url: image, isBypass: true }))?.file));
                        const _images = images.map(({ _id }) => _id);
                        await Promise.all(product._images.filter((_id) => !_images.includes(_id)).map(async (_id) => await api.console.files.delete({ _id })));

                        product = (await put({ _id: product._id, _thumb, _images }))?.product;

                        this.result.products.push(product);
                        this.emit();
                    } catch (error) {
                        this.result.products.push({ ...parsedProduct, errors: [...(parsedProduct.errors || []), error] });
                        this.emit();
                    }
                }
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
