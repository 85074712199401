<template></template>

<script>
import XLSX from "xlsx";

import { initShopBulkUploadData } from "@/assets/variables";

const sheetName = "1.카테고리등록";

const nameData = (row) => ({
    rawData: row,

    ["카테고리1"]: `${row?.A || ""}`?.trim?.() || null,
    ["카테고리2"]: `${row?.B || ""}`?.trim?.() || null,
    ["카테고리3"]: `${row?.C || ""}`?.trim?.() || null,
    ["카테고리코드"]: `${row?.D || ""}`?.trim?.() || null,
});

const structureData = ({ rawData, ...nameData }) => {
    const parents = [
        {
            name: nameData["카테고리1"],
            depth: 1,
        },
        {
            name: nameData["카테고리2"],
            depth: 2,
        },
        {
            name: nameData["카테고리3"],
            depth: 3,
        },
    ].filter(({ name }) => name);

    const category = parents.pop();
    category.code = nameData["카테고리코드"];

    return {
        parents,

        code: category.code,
        name: category.name,
        depth: category.depth,

        rawData,
        nameData,
    };
};

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    mounted() {
        this.process();
    },
    watch: {
        file() {
            this.process();
        },
    },
    methods: {
        process() {
            if (!this.file) return;

            const reader = new FileReader();
            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: sheetName });
                const sheet = workBook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { header: "A", range: 3 });

                const categories = rawData.map(nameData).map(structureData);

                this.$emit("input", initShopBulkUploadData({ ...this.value, categories }));
                this.$emit("loaded");
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>
