<template>
    <v-layout justify-center>
        <v-responsive max-width="960" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="자주묻는 질문">
                <template #add-button>
                    <faq-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n2" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </faq-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" :items-per-page="-1" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" style="overflow: hidden" ref="data-table">
                <template #top>
                    <v-tabs>
                        <v-tab exact :to="{ query: { type: undefined } }">전체</v-tab>
                        <template v-for="type in types">
                            <v-tab exact :to="{ query: { ...$route.query, type: type.value } }" :key="type.value">{{ type.text }}</v-tab>
                        </template>
                    </v-tabs>
                    <v-divider />
                </template>

                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :disabled="!$route.query.type || loading" @change="setNewIndex">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.faq`]="{ item }">
                    <v-row @click="$set(item, 'show', !item.show)">
                        <v-col cols="auto" class="text-start">
                            <span v-if="item.typeText"> [{{ item.typeText }}] </span>
                            {{ item.subject }}
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                            <v-icon v-else>mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row v-show="item.show">
                            <v-col cols="12" class="text-start">
                                <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </template>

                <template #[`item.actions`]="{ item }">
                    <faq-form :_id="item._id" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </faq-form>
                    <v-btn text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { FAQ_TYPES } from "@/assets/variables";

import Draggable from "vuedraggable";

import FaqForm from "@/components/console/center/faqs/faq-form.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

const types = Object.values(FAQ_TYPES);

const headers = [
    { width: +60, text: "#", value: "index", align: "end" },
    { width: 614, text: "FAQ", value: "faq" },
    { width: 160, text: "작성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
    { width: 100, text: "", value: "actions", align: "center", cellClass: "px-0" },
].map((item, index, array) => ({
    ...item,
    divider: index != array.length - 1,
    cellClass: item.cellClass + ` caption`,
    formatter: item.formatter ?? ((value) => value ?? "-"),
}));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,

        FaqForm,
        ListHeading,
        NaverSmarteditor,
    },
    data: () => ({
        faqs: [],

        limit: 10,
        summary: { totalCount: 0 },

        types,
        headers,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.faqs];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, faqs } = await api.console.center.faqs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.faqs = faqs;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.faqs.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.faqs.splice(index, 1, item);
            else {
                this.faqs = [item, ...this.faqs];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(faq) {
            try {
                if (confirm("해당 FAQ를 삭제하시겠습니까?")) {
                    await api.console.center.faqs.delete(faq);
                    await this.search();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let {
                    element: { _id, index },
                    oldIndex,
                    newIndex,
                } = moved;
                console.log({ index, oldIndex, newIndex });
                index += oldIndex - newIndex;
                let form = { _id, index };
                let { faq } = await api.console.center.faqs.put(form);
                this.updateItem(faq);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
