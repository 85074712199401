<template>
    <v-btn v-bind="{ ...$attrs, loading, disabled }" @click="commit"> {{ text }} </v-btn>
</template>

<script>
import api from "@/api";

import { initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        result: initShopBulkUploadData(),
    }),
    computed: {
        disabled() {
            return this.parsed?.brands?.length <= this.value?.brands?.length;
        },
        text() {
            return this.disabled ? "처리완료" : "등록처리";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        async commit() {
            if (this.loading) return;
            else this.$emit("setLoading", true);

            const { post, put } = api.console.shop.brands;
            try {
                for (const parsedBrand of this.parsed.brands) {
                    ////////////////////////////////////////////////////////////
                    // POST, PUT document
                    ////////////////////////////////////////////////////////////
                    let { label, thumb, image, ...form } = parsedBrand;
                    let { brand } = await api.v1.shop.brands.get({ code: form.code });
                    if (brand) form._id = brand._id;

                    delete form.rawData;
                    delete form.nameData;

                    brand = (await (brand ? put : post)(form))?.brand;

                    ////////////////////////////////////////////////////////////
                    // POST, PUT resources
                    ////////////////////////////////////////////////////////////
                    let _label = null;
                    if (label) {
                        if (brand.label?.url == label) label = brand.label;
                        else label = (await api.console.files.post__bypass({ _brand: brand._id, url: label, isBypass: true }))?.file;
                    }
                    _label = label?._id || null;

                    let _thumb = null;
                    if (thumb) {
                        if (brand.thumb?.url == thumb) thumb = brand.thumb;
                        else thumb = (await api.console.files.post__bypass({ _brand: brand._id, url: thumb, isBypass: true }))?.file;
                    }
                    _thumb = thumb?._id || null;

                    let _image = null;
                    if (image) {
                        if (brand.image?.url == image) image = brand.image;
                        else image = (await api.console.files.post__bypass({ _brand: brand._id, url: image, isBypass: true }))?.file;
                    }
                    _image = image?._id || null;

                    brand = (await put({ _id: brand._id, _label, _thumb, _image }))?.brand;
                    this.result.brands.push(brand);
                    this.emit();
                }
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
