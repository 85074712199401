<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <v-col cols="12" class="py-3 px-4">
                    <div class="font-weight-bold grey--text">발급대상</div>
                    {{ targetTypeText }}
                </v-col>
                <v-col cols="12" class="py-3 px-4">
                    <div class="font-weight-bold grey--text">대상{{ targetTypeText }}</div>
                    <template v-if="issue.target.type == ISSUE_TARGET_TYPES.USERS.value">
                        <autocomplete-user :value="this.issue.target._users" readonly multiple placeholder="전체" />
                    </template>
                    <template v-if="issue.target.type == ISSUE_TARGET_TYPES.USER_LEVELS.value">
                        <autocomplete-user-level :value="this.issue.target._levels" readonly multiple placeholder="전체" />
                    </template>
                    <template v-if="issue.target.type == ISSUE_TARGET_TYPES.USER_TYPES.value">
                        {{ userTypeTexts }}
                    </template>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { initIssue, ISSUE_TARGET_TYPES } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteUserLevel from "@/components/dumb/autocomplete-user-level.vue";

export default {
    components: {
        AutocompleteUser,
        AutocompleteUserLevel,
    },
    props: {
        issue: { type: Object, default: initIssue },
    },
    data: () => ({
        ISSUE_TARGET_TYPES,
    }),
    computed: {
        targetTypeText() {
            return this.issue.target.typeText;
        },
        userTypeTexts() {
            return this.issue.target.userTypeTexts?.join?.(", ");
        },
    },
};
</script>

<style>
</style>