var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', _vm._b({
    staticClass: "caption",
    attrs: {
      "color": "transparent"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.charge.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.charge, "type", $$v);
      },
      expression: "form.charge.type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__verticalTable, {
    items: _vm.items,
    readonly: _vm.readonly,
    appendIcon: _vm.appendIcon
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }