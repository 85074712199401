<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2 pt-0 px-10">
                        <span class="caption">배너</span>
                        <v-row align="center">
                            <v-col cols="3" class="py-0">
                                <image-input v-model="form.image" accept="image/*" width="122" height="122" outlined style="overflow: hidden" />
                            </v-col>
                            <v-col>
                                <v-row no-gutters>
                                    <v-col cols="12" md="4" class="pa-2"> <v-text-field v-model="form.index" type="number" label="#순서" v-bind="{ ...attrs_input__console, loading }" /> </v-col>
                                    <v-col cols="12" md="8" class="pa-2"> <v-text-field v-model="form.title" label="제목" v-bind="{ ...attrs_input__console, loading }" /> </v-col>
                                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.url" label="URL" v-bind="{ ...attrs_input__console, loading }" /> </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-card-text>
                            <naver-smarteditor v-model="form.content" rows="10" :key="form._id"/>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "표기" : "미표기" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

const initBannerAdvertisement = (advertisement = {}) => ({
    ...advertisement,
    _image: advertisement?._image || null,
    image: advertisement?.image || null,

    code: advertisement?.code || "MAIN_ADVERTISEMENT",
    index: advertisement?.index || 0,

    title: advertisement?.title || null,
    content: advertisement?.content || null,
    url: advertisement?.url || null,
    shows: advertisement?.shows || false,
});

export default {
    components: {
        ImageInput,
        NaverSmarteditor,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initBannerAdvertisement(),
        dialog: false,
        loading: false,

        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = `메인 광고배너`;
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initBannerAdvertisement();
                } else {
                    let { _id } = this;
                    this.form = initBannerAdvertisement((await api.console.bannerAdvertisements.get({ _id }))?.advertisement);
                    this.$emit("input", { ...this.form });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { image, content } = this.form;
            if (!image) throw new Error("배너를 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.bannerAdvertisements;
                let { _image, image, ...form } = this.form;
                let { _id: _bannerAdvertisement } = (await (this.isCreate ? post : put)(form))?.advertisement;
                
                if (image instanceof File) image = (await api.console.files.post({ _bannerAdvertisement }, image))?.file;

                if(_image && image._id !== _image) await api.console.files.delete({ _id: _image });

                _image = image?._id || null;

                let advertisement = (await put({ _id: _bannerAdvertisement, _image }))?.advertisement; 

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", advertisement);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
