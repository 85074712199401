<template>
    <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering class="product-bundleds-table">
        <template #[`header.variation.spec`]>
            <v-row no-gutters class="fill-height" align="center">
                <template v-for="(text, index) in headers__spec">
                    <v-col :key="`input-${index}`" class="px-3"> {{ text }} </v-col>
                    <v-col v-if="index != form.variation.spec.length - 1" cols="auto" :key="`divider-${index}`" align-self="stretch"> <v-divider vertical /> </v-col>
                </template>
            </v-row>
        </template>

        <template #[`item.variation.head`]="{ item, value }">
            <autocomplete-product v-model="item.variation.head" v-bind="props__head" class="caption" @input="emit">
                <template #append v-if="item.variation.head">
                    <v-btn x-small plain icon text :to="`/console/shop/products/${value?._id}`">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
            </autocomplete-product>
        </template>

        <template #[`item.variation.spec`]="{ item }">
            <v-row no-gutters>
                <template v-for="(_, index) in headers__spec">
                    <v-col :key="`input-${index}`">
                        <v-text-field v-model="item.variation.spec[index]" v-bind="attrs_input__verticalTable" class="caption" @input="emit" />
                    </v-col>
                    <v-col v-if="index != form.variation.spec.length - 1" cols="auto" :key="`divider-${index}`"> <v-divider vertical /> </v-col>
                </template>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, initDataTableHeaders, initProduct, PRODUCT_TYPES } from "@/assets/variables";

import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

export default {
    components: {
        AutocompleteProduct,
    },
    props: {
        value: { type: Object, default: initProduct },
    },
    data: () => ({
        form: initProduct(),

        attrs_input__verticalTable,
    }),
    computed: {
        headers() {
            return initDataTableHeaders([
                {
                    text: "묶음상품코드",
                    value: "variation.head",
                    width: 240,
                    class: "px-3",
                    cellClass: "pa-0 caption",
                },
                {
                    text: "Spec",
                    value: "variation.spec",
                    class: "pa-0",
                    cellClass: "pa-0",
                },
            ]);
        },

        headers__spec() {
            return this.form?.variation?.head?.variation?.spec || [];
        },

        items() {
            return [this.form];
        },

        props__head() {
            return {
                itemText: ({ code, name }) => `${code} (${name})`,
                params: { type: PRODUCT_TYPES.VARIETY_BUNDLE.value },
                clearable: true,
                returnObject: true,
                ...attrs_input__verticalTable,
            };
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        background-color: rgba(255, 255, 255, 0) !important;

        &:hover {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}
.product-bundleds-table ::v-deep {
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        display: block;
        height: 100%;
    }
}
</style>
