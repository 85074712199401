var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    on: {
      "click": _vm.commit
    }
  }, 'v-btn', Object.assign({}, _vm.$attrs, {
    loading: _vm.loading,
    disabled: _vm.disabled
  }), false), [_vm._v(" " + _vm._s(_vm.text) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }