var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticStyle: {
      "overflow": "hidden",
      "max-height": "calc(100vh - 48px - 12px - 12px)"
    },
    attrs: {
      "column": ""
    }
  }, [_c('list-heading', {
    staticStyle: {
      "flex": "0 0 auto"
    },
    attrs: {
      "title": "대량등록"
    }
  }), _c('v-tabs', {
    staticStyle: {
      "flex": "0 0 auto"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("파일선택")]), _c('v-tab', [_vm._v("내용확인")]), _c('v-tab', [_vm._v("등록처리")])], 1), _c('v-divider'), _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "height": "calc(100% - 48px - 1px)",
      "overflow": "hidden"
    }
  }, [_c('v-tabs-items', {
    staticClass: "fill-height",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    staticClass: "fill-height",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('tab-upload', {
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('tab-verify', _vm._b({
    model: {
      value: _vm.parsed,
      callback: function ($$v) {
        _vm.parsed = $$v;
      },
      expression: "parsed"
    }
  }, 'tab-verify', {
    file: _vm.file,
    result: _vm.result
  }, false))], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('tab-commit', _vm._b({
    model: {
      value: _vm.result,
      callback: function ($$v) {
        _vm.result = $$v;
      },
      expression: "result"
    }
  }, 'tab-commit', {
    file: _vm.file,
    parsed: _vm.parsed
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }