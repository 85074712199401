<template>
    <v-btn v-bind="{ ...$attrs, loading, disabled }" @click="commit"> {{ text }} </v-btn>
</template>

<script>
import api from "@/api";

import { initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData }, // result
        parsed: { type: Object, default: initShopBulkUploadData },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        result: initShopBulkUploadData(),
    }),
    computed: {
        disabled() {
            return this.parsed?.bundleds?.length <= this.value?.bundleds?.length;
        },
        text() {
            return this.disabled ? "처리완료" : "등록처리";
        },
        products() {
            return this.parsed.bundleds.reduce((products, bundled) => {
                let product = products.find(({ code }) => code == bundled.parent);
                if (!product) {
                    product = { code: bundled.parent, bundleds: [] };
                    products.push(product);
                }
                product.bundleds.push(bundled);
                return products;
            }, []);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.result = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.result);
        },
        async commit() {
            if (this.loading) return;
            else this.$emit("setLoading", true);

            const { post, put } = api.console.shop.products.bundleds;
            try {
                for (let { bundleds, ...product } of this.products) {
                    try {
                        product = this.result.products.find(({ code }) => code == product.code) || (await api.v1.shop.products.get({ code: product.code, headers: { mode: "code" } }))?.product;

                        if (!product) throw new Error("상품을 찾을 수 없습니다.");
                    } catch (error) {
                        this.result.bundleds.push(...bundleds.map((item) => ({ ...item, errors: [...(item.errors || []), error] })));
                        continue;
                    }

                    for (let [index, bundled] of bundleds.entries()) {
                        try {
                            ////////////////////////////////////////////////////////
                            // POST, PUT document
                            ////////////////////////////////////////////////////////
                            let { ...form } = bundled;
                            bundled = product.bundleds.find(({ code }) => code == form.target) || bundled;

                            if (bundled?._id) {
                                form._id = bundled._id;
                                form._product = bundled._product;
                                // form._option = bundled._option;
                                // form._supply = bundled._supply;
                            } else {
                                let product = (await api.v1.shop.products.get({ code: form.target, headers: { mode: "code" } }))?.product;
                                if (product) form._product = product._id;
                                else throw new Error("필수상품을 찾을 수 없습니다.");
                            }

                            delete form.rawData;
                            delete form.nameData;

                            bundled = (await (form?._id ? put : post)(form))?.bundled;

                            this.result.bundleds.push(bundled);
                            bundleds[index] = bundled;

                            this.emit();
                        } catch (error) {
                            this.result.bundleds.push({ ...bundled, errors: [...(bundled.errors || []), error] });
                            this.emit();
                        }
                    }

                    try {
                        await api.console.shop.products.put({
                            _id: product._id,
                            _bundleds: [...new Set([...product._bundleds, ...bundleds.map(({ _id }) => _id)])].filter((item) => item),
                        });
                    } catch (error) {
                        this.result.bundleds.push(this.result.bundleds.slice(-bundleds.length).map((item) => ({ ...item, errors: [...(item.errors || []), error] })));
                    }
                }
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
