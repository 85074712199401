var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "product-bundleds-table",
    attrs: {
      "items": _vm.form.variation.list,
      "item-key": "name"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `header.variation.spec`,
      fn: function () {
        var _vm$form, _vm$form$variation, _vm$form$variation$sp;
        return [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_vm._l(_vm.form.variation.spec, function (_, index) {
          return [_c('v-col', {
            key: `input-${index}`
          }, [_c('v-text-field', _vm._b({
            staticClass: "caption font-weight-bold",
            on: {
              "input": _vm.emit
            },
            scopedSlots: _vm._u([index ? {
              key: "append",
              fn: function () {
                return [_c('v-icon', {
                  attrs: {
                    "color": "red"
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.pull(index);
                    }
                  }
                }, [_vm._v("mdi-minus")])];
              },
              proxy: true
            } : {
              key: "append",
              fn: function () {
                return [_c('v-icon', {
                  attrs: {
                    "color": "primary"
                  },
                  on: {
                    "click": _vm.push
                  }
                }, [_vm._v("mdi-plus")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.form.variation.spec[index],
              callback: function ($$v) {
                _vm.$set(_vm.form.variation.spec, index, $$v);
              },
              expression: "form.variation.spec[index]"
            }
          }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1), index != _vm.form.variation.spec.length - 1 ? _c('v-col', {
            key: `divider-${index}`,
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-divider', {
            attrs: {
              "vertical": ""
            }
          })], 1) : _vm._e()];
        }), !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$variation = _vm$form.variation) !== null && _vm$form$variation !== void 0 && (_vm$form$variation$sp = _vm$form$variation.spec) !== null && _vm$form$variation$sp !== void 0 && _vm$form$variation$sp.length) ? [_c('v-col', [_c('v-text-field', _vm._b({
          staticClass: "caption font-weight-bold",
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.push(2);
                  }
                }
              }, [_vm._v("mdi-plus")])];
            },
            proxy: true
          }], null, false, 815614289),
          model: {
            value: _vm.form.variation.spec[0],
            callback: function ($$v) {
              _vm.$set(_vm.form.variation.spec, 0, $$v);
            },
            expression: "form.variation.spec[0]"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1)] : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: `item.name`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('v-row', [_c('v-col', {
          staticClass: "py-0"
        }, [_vm._v(" " + _vm._s(value) + " ")]), _c('v-col', {
          staticClass: "py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "plain": "",
            "icon": "",
            "text": "",
            "to": `/console/shop/products/${item._id}`
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)], 1)], 1)];
      }
    }, {
      key: `item.variation.spec`,
      fn: function (_ref3) {
        var _vm$form2, _vm$form2$variation, _vm$form2$variation$s;
        var item = _ref3.item;
        return [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_vm._l(_vm.form.variation.spec, function (_, index) {
          return [_c('v-col', {
            key: `input-${index}`
          }, [_c('v-text-field', _vm._b({
            staticClass: "caption",
            on: {
              "input": _vm.emit
            },
            model: {
              value: item.variation.spec[index],
              callback: function ($$v) {
                _vm.$set(item.variation.spec, index, $$v);
              },
              expression: "item.variation.spec[index]"
            }
          }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1), index != _vm.form.variation.spec.length - 1 ? _c('v-col', {
            key: `divider-${index}`,
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-divider', {
            attrs: {
              "vertical": ""
            }
          })], 1) : _vm._e()];
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$variation = _vm$form2.variation) !== null && _vm$form2$variation !== void 0 && (_vm$form2$variation$s = _vm$form2$variation.spec) !== null && _vm$form2$variation$s !== void 0 && _vm$form2$variation$s.length) ? [_c('v-col', [_c('v-text-field', _vm._b({
          staticClass: "caption",
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.variation.spec[0],
            callback: function ($$v) {
              _vm.$set(item.variation.spec, 0, $$v);
            },
            expression: "item.variation.spec[0]"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1)] : _vm._e()], 2)];
      }
    }, {
      key: `item.variation.enabled`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-simple-checkbox', {
          staticClass: "d-inline-block mr-n2",
          attrs: {
            "dense": "",
            "color": "primary"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.variation.enabled,
            callback: function ($$v) {
              _vm.$set(item.variation, "enabled", $$v);
            },
            expression: "item.variation.enabled"
          }
        })];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }