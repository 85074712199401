var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.readonly ? _c('span', [_vm.multiple ? [_vm._v(" " + _vm._s(_vm.items.filter(function (_ref) {
    var _id = _ref._id;
    return _vm.value.includes(_id);
  }).map(function () {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      name = _ref2.name;
    return name;
  }).join(", ")) + " ")] : [_vm._v(" " + _vm._s(_vm.itemText(_vm.value)) + " ")]], 2) : _c('v-autocomplete', _vm._b({
    attrs: {
      "value": _vm.value
    },
    on: {
      "change": function (changedValue) {
        return _vm.$emit('input', changedValue);
      },
      "click": _vm.alert
    },
    scopedSlots: _vm._u([_vm.$scopedSlots['selection'] ? {
      key: "selection",
      fn: function (_ref3) {
        var parent = _ref3.parent,
          item = _ref3.item,
          attrs = _ref3.attrs;
        return [_vm._t("selection", null, null, {
          parent,
          item,
          attrs,
          itemText: _vm.itemText
        })];
      }
    } : null], null, true)
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    itemText: _vm.itemText,
    itemValue: '_id',
    multiple: _vm.multiple
  }), false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }