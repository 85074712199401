<template>
    <v-data-table :items="form.variation.list" v-bind="{ headers }" item-key="name" class="product-bundleds-table">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

        <template #[`header.variation.spec`]>
            <v-row no-gutters>
                <template v-for="(_, index) in form.variation.spec">
                    <v-col :key="`input-${index}`">
                        <v-text-field v-model="form.variation.spec[index]" v-bind="attrs_input__verticalTable" class="caption font-weight-bold" @input="emit">
                            <template #append v-if="index"> <v-icon color="red" @click="pull(index)">mdi-minus</v-icon> </template>
                            <template #append v-else> <v-icon color="primary" @click="push">mdi-plus</v-icon> </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="index != form.variation.spec.length - 1" cols="auto" :key="`divider-${index}`"> <v-divider vertical /> </v-col>
                </template>
                <template v-if="!form?.variation?.spec?.length">
                    <v-col>
                        <v-text-field v-model="form.variation.spec[0]" v-bind="attrs_input__verticalTable" class="caption font-weight-bold" @input="emit">
                            <template #append> <v-icon color="primary" @click="push(2)">mdi-plus</v-icon> </template>
                        </v-text-field>
                    </v-col>
                </template>
            </v-row>
        </template>

        <template #[`item.name`]="{ item, value }">
            <v-row>
                <v-col class="py-0"> {{ value }} </v-col>
                <v-col cols="auto" class="py-0">
                    <v-btn x-small plain icon text :to="`/console/shop/products/${item._id}`">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template #[`item.variation.spec`]="{ item }">
            <v-row no-gutters>
                <template v-for="(_, index) in form.variation.spec">
                    <v-col :key="`input-${index}`">
                        <v-text-field v-model="item.variation.spec[index]" v-bind="attrs_input__verticalTable" class="caption" @input="emit" />
                    </v-col>
                    <v-col v-if="index != form.variation.spec.length - 1" cols="auto" :key="`divider-${index}`"> <v-divider vertical /> </v-col>
                </template>
                <template v-if="!form?.variation?.spec?.length">
                    <v-col>
                        <v-text-field v-model="item.variation.spec[0]" v-bind="attrs_input__verticalTable" class="caption" @input="emit" />
                    </v-col>
                </template>
            </v-row>
        </template>

        <template #[`item.variation.enabled`]="{ item }">
            <v-simple-checkbox v-model="item.variation.enabled" dense color="primary" class="d-inline-block mr-n2" @input="emit" />
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, initDataTableHeaders, initProduct } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initProduct },
    },
    data: () => ({
        form: initProduct(),

        attrs_input__verticalTable,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    computed: {
        headers() {
            const headers = [
                { text: "상품명", value: "name", formatter: (value, item) => item?.name },
                { text: "상품코드", value: "code", formatter: (value, item) => item?.code, width: 120 },
                { text: "재고", value: "stock", formatter: (value, item) => `${(item?.stock || 0).format()}개`, width: +80 },
                { text: "판매가격", value: "salePrice", formatter: (value, item) => `${(item?.salePrice || 0).format()}원`, width: 120 },
                { text: "Spec", value: "variation.spec", sortable: false, class: "pa-0", cellClass: "pa-0", width: 480 },
                { text: "사용", value: "variation.enabled", align: "center", width: +64, sortable: false, cellClass: "pa-0" },
            ];

            return initDataTableHeaders(headers);
        },
    },
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },

        emit() {
            this.$emit("input", this.form);
        },

        push(times = 1) {
            this.form.variation.spec.push(...new Array(times).fill(""));
            this.emit();
        },

        pull(index) {
            this.form.variation.spec.splice(index, 1);
            for (const product of this.form.variation.list) {
                product.variation.spec.splice(index, 1);
            }
            this.emit();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        background-color: rgba(255, 255, 255, 0) !important;

        &:hover {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}
.product-bundleds-table ::v-deep {
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        display: block;
        height: 100%;
    }
}
</style>
