<template>
    <v-layout justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading :title="`${banner?.title || '배너'}`" />
            <v-card outlined style="overflow: hidden" v-if="banner">
                <template v-if="single">
                    <v-row no-gutters style="min-height: calc(100vh - 48px - 12px - 32px - 56px - 16px - 16px - 12px)">
                        <v-col style="border-right: 1px solid rgba(0, 0, 0, 0.12)"> <banner-input label="Desktop" :sublabel="banner.sublabel__pc" :name="`${banner.title} Desktop`" :code="banner.code__pc" /> </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-row no-gutters style="min-height: calc(100vh - 48px - 12px - 32px - 56px - 16px - 16px - 12px)">
                        <v-col cols="6" style="border-right: 1px solid rgba(0, 0, 0, 0.12)"> <banner-input label="Desktop" :sublabel="banner.sublabel__pc" :name="`${banner.title} Desktop`" :code="banner.code__pc" /> </v-col>
                        <v-col cols="6"> <banner-input label="Mobile" :sublabel="banner.sublabel__mb" :name="`${banner.title} Mobile`" :code="banner.code__mb" /> </v-col>
                    </v-row>
                </template>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import { BANNERS } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import BannerInput from "@/components/console/banners/banner-input.vue";

export default {
    components: {
        ListHeading,
        BannerInput,
    },
    props: {
        code: { type: String },
    },
    computed: {
        banner() {
            return BANNERS[this.code] || null;
        },
        single() {
            return this.banner?.isSingle ?? false;
        },
    },
};
</script>
