var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({}, 'v-card', _vm.$attrs, false), [_c('v-card-text', {
    staticClass: "caption"
  }, [_c('v-row', {
    staticClass: "ma-n4",
    staticStyle: {
      "word-break": "keep-all"
    }
  }, [_c('v-col', {
    staticClass: "py-3 px-4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.durationText) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }