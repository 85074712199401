var render = function render(){
  var _vm$review, _vm$review2, _vm$review3, _vm$review3$images, _vm$review4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({}, 'v-card', _vm.$attrs, false), [_c('v-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsUser,
      expression: "showsUser"
    }],
    staticClass: "mt-1 mb-n4",
    attrs: {
      "draggable": false
    }
  }, [_c('v-list-item-avatar', {
    staticClass: "mr-2",
    attrs: {
      "color": "grey",
      "size": "30"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-account")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "subtitle-2 text-truncate"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.user.username || "-") + " ")]), _vm._v(" 님 ")])], 1)], 1), _c('v-card-subtitle', {
    staticClass: "caption font-weight-bold"
  }, [_c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "dense": "",
      "x-small": "",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": (_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : _vm$review.star
    }
  })], 1), _c('v-card-text', {
    staticClass: "caption white-space-pre-line",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_vm._v(" " + _vm._s((_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : _vm$review2.content) + " "), (_vm$review3 = _vm.review) !== null && _vm$review3 !== void 0 && (_vm$review3$images = _vm$review3.images) !== null && _vm$review3$images !== void 0 && _vm$review3$images.length ? [_c('image-tiles', {
    staticClass: "pt-4",
    attrs: {
      "images": (((_vm$review4 = _vm.review) === null || _vm$review4 === void 0 ? void 0 : _vm$review4.images) || []).map(function (_ref) {
        var src = _ref.src;
        return src;
      }),
      "tilesOnly": ""
    }
  })] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }