<template>
    <v-data-table v-bind="{ items, headers }" disable-sort fixed-header>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData },
        isError: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return this.value?.options || [];
        },
        headers() {
            const headers = [
                { width: +80, value: "product", text: "제품 상품코드" },
                { width: +80, value: "values.0.value", text: "옵션1" },
                { width: +80, value: "values.1.value", text: "옵션2" },
                { width: +80, value: "values.2.value", text: "옵션3" },
                { width: +80, value: "values.3.value", text: "옵션4" },
                { width: +80, value: "values.4.value", text: "옵션5" },
                { width: +80, value: "values.5.value", text: "옵션6" },
                { width: +80, value: "values.6.value", text: "옵션7" },
                { width: +80, value: "code", text: "필수옵션 상품코드" },
                { width: +80, value: "stock", text: "재고", formatter: (value) => value?.format?.() || value || "-" },
                { width: +80, value: "price", text: "총 상품가격", formatter: (value) => value?.format?.() || value || "-" },
            ];

            if (this.isError) headers.push({ width: 480, value: "errors", text: "오류", formatter: (errors) => errors.map((error) => error.message).join("\r\n") });

            return initDataTableHeaders(headers.map((header) => ({ ...header, class: (header.class || "") + " word-break-keep-all", cellClass: (header.cellClass || "") + " text-truncate max-width-0 py-3", withTitle: true })));
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .word-break-keep-all {
        word-break: keep-all;
    }
    .max-width-0 {
        max-width: 0px;
    }
}
</style>
