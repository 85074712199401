var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    staticClass: "px-3",
    attrs: {
      "column": ""
    }
  }, [_c('draggable', {
    attrs: {
      "list": _vm.items
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('options-criteria-color-form', {
      key: item.tempId,
      on: {
        "input": _vm.emit
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('options-criteria-color-item', _vm._b({
            staticClass: "ma-1",
            attrs: {
              "value": item
            },
            on: {
              "remove": function ($event) {
                return _vm.remove(item);
              }
            }
          }, 'options-criteria-color-item', {
            attrs,
            on
          }, false))];
        }
      }], null, true),
      model: {
        value: _vm.items[index],
        callback: function ($$v) {
          _vm.$set(_vm.items, index, $$v);
        },
        expression: "items[index]"
      }
    })];
  }), _c('options-criteria-color-form', {
    on: {
      "input": _vm.add
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-chip', _vm._g(_vm._b({
          staticClass: "ma-1",
          attrs: {
            "outlined": "",
            "label": "",
            "ripple": false
          }
        }, 'v-chip', attrs, false), on), [_c('v-avatar', {
          staticClass: "v-sheet--outline",
          attrs: {
            "size": "40"
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _vm._v(" 항목추가 ")], 1)];
      }
    }])
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }