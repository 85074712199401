<template>
    <v-sheet class="pa-4 fill-height">
        <v-sheet outlined class="fill-height">
            <v-file-btn v-model="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" text block tile height="100%" @input="emit">
                <v-card-title>
                    {{ text }}
                </v-card-title>
                <v-icon large>mdi-file-excel</v-icon>
            </v-file-btn>
        </v-sheet>
    </v-sheet>
</template>

<script>
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
export default {
    components: {
        VFileBtn,
    },
    props: {
        value: { type: File, default: null },
    },
    data: () => ({
        file: null,
    }),
    computed: {
        text() {
            let text = "";

            if (this.file instanceof File) {
                const { name, size } = this.file;
                if (name) text += name;
                if (text) text += " / ";
                if (size) {
                    const kb = size / 1024;
                    text += `${kb.toFixed(1)}KB`;
                }
            }

            return text || "대량등록 양식파일을 업로드하세요";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.file = this.value || null;
        },
        emit() {
            this.$emit("input", this.file || null);
        },
    },
};
</script>

<style></style>
