var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "fill-height",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "margin-bottom": "-1px"
    }
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view"
    },
    scopedSlots: _vm._u([{
      key: "기준",
      fn: function () {
        var _vm$criteria$join, _vm$criteria;
        return [_vm._v(" " + _vm._s(((_vm$criteria$join = (_vm$criteria = _vm.criteria).join) === null || _vm$criteria$join === void 0 ? void 0 : _vm$criteria$join.call(_vm$criteria, "\n")) || "기준없음") + " ")];
      },
      proxy: true
    }, {
      key: "혜택",
      fn: function () {
        var _vm$benefits$join, _vm$benefits;
        return [_vm._v(" " + _vm._s((_vm$benefits$join = (_vm$benefits = _vm.benefits).join) === null || _vm$benefits$join === void 0 ? void 0 : _vm$benefits$join.call(_vm$benefits, "\n")) + " ")];
      },
      proxy: true
    }])
  }, 'vertical-form-table', {
    value: _vm.value,
    items: _vm.items
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }