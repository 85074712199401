<template>
    <v-layout column fill-height style="overflow: hidden">
        <v-tabs v-model="tab" style="flex: 0 0 auto">
            <template v-for="{ text, value } in items">
                <v-tab :tab-value="value" :key="value">{{ text }}</v-tab>
            </template>
        </v-tabs>
        <v-divider />
        <v-sheet style="height: calc(100% - 48px - 1px); overflow: hidden">
            <v-tabs-items v-model="tab" style="height: 100%">
                <template v-for="({ value }, tabindex) in items">
                    <v-tab-item class="fill-height" :key="value" v-bind="{ value, tabindex }">
                        <template v-if="value == 'brands'">
                            <verify-brands v-model="parsed" />
                        </template>
                        <template v-if="value == 'bundleds'">
                            <verify-bundleds v-model="parsed" />
                        </template>
                        <template v-if="value == 'categories'">
                            <verify-categories v-model="parsed" />
                        </template>
                        <template v-if="value == 'choicees'">
                            <verify-choicees v-model="parsed" />
                        </template>
                        <template v-if="value == 'options'">
                            <verify-options v-model="parsed" />
                        </template>
                        <template v-if="value == 'products'">
                            <verify-products v-model="parsed" />
                        </template>
                        <template v-if="value == 'relateds'">
                            <verify-relateds v-model="parsed" />
                        </template>
                    </v-tab-item>
                </template>
            </v-tabs-items>
        </v-sheet>
        <v-fade-transition>
            <v-overlay absolute light color="white" class="black--text" v-show="!file">
                <v-card-title> 파일을 먼저 등록해주세요 </v-card-title>
            </v-overlay>
        </v-fade-transition>
        <v-fade-transition>
            <v-overlay absolute light color="white" class="black--text" v-show="file && items.length == 0">
                <v-card-title> 파일 양식이 잘못되었습니다 </v-card-title>
            </v-overlay>
        </v-fade-transition>
        <process-categories v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
        <process-brands v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
        <process-bundleds v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
        <process-products v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
        <process-options v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
        <process-choicees v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
        <process-relateds v-model="parsed" v-bind="{ file }" @input="emit" @loaded="loaded += 1" />
    </v-layout>
</template>

<script>
import { initShopBulkUploadData, SHOP_BULK_UPLOAD_KEYS } from "@/assets/variables";

import ProcessBrands from "./process/process-brands.vue";
import ProcessBundleds from "./process/process-bundleds.vue";
import ProcessCategories from "./process/process-categories.vue";
import ProcessChoicees from "./process/process-choicees.vue";
import ProcessOptions from "./process/process-options.vue";
import ProcessProducts from "./process/process-products.vue";
import ProcessRelateds from "./process/process-relateds.vue";
import VerifyBrands from "./verify/verify-brands.vue";
import VerifyBundleds from "./verify/verify-bundleds.vue";
import VerifyCategories from "./verify/verify-categories.vue";
import VerifyChoicees from "./verify/verify-choicees.vue";
import VerifyOptions from "./verify/verify-options.vue";
import VerifyProducts from "./verify/verify-products.vue";
import VerifyRelateds from "./verify/verify-relateds.vue";

export default {
    components: {
        ProcessBrands,
        ProcessBundleds,
        ProcessCategories,
        ProcessChoicees,
        ProcessOptions,
        ProcessProducts,
        ProcessRelateds,
        VerifyBrands,
        VerifyBundleds,
        VerifyCategories,
        VerifyChoicees,
        VerifyOptions,
        VerifyProducts,
        VerifyRelateds,
    },
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData }, // parsed
        result: { type: Object, default: initShopBulkUploadData },
    },
    data: () => ({
        tab: 0,
        loaded: 0,

        parsed: initShopBulkUploadData(),
    }),
    computed: {
        items() {
            return Object.keys(this.parsed).reduce((items, key) => {
                if (this.parsed[key]?.length)
                    items.push({
                        text: SHOP_BULK_UPLOAD_KEYS[key].text,
                        value: key,
                    });
                return items;
            }, []);
        },
    },
    mounted() {
        this.init();
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        file() {
            this.init();
        },
        items() {
            this.tab = this.items[0]?.value || 0;
        },
    },
    methods: {
        init() {
            this.loaded = 0;
        },
        sync() {
            this.parsed = initShopBulkUploadData(this.value);
        },
        emit() {
            this.$emit("input", this.parsed);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table {
        height: 100%;
    }
    .v-data-table__wrapper {
        max-height: calc(100% - 59px);
    }
    .v-data-footer {
        margin: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}
</style>
