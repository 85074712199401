var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("팝업관리")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.popupsHeaders,
      "items": _vm.popups,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.no",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + _vm.popups.indexOf(item) + 1) + " ")];
      }
    }, {
      key: "item.link",
      fn: function (_ref2) {
        var _item$content;
        var item = _ref2.item;
        return [_c('v-row', {
          on: {
            "click": function ($event) {
              return _vm.$set(item, 'show', !item.show);
            }
          }
        }, [_c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "4"
          }
        }, [_c('a', {
          attrs: {
            "href": item.url,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(item.url))])]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [!item.show ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-up")])], 1)], 1), _c('v-expand-transition', [_c('v-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.show,
            expression: "item.show"
          }]
        }, [_c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "12"
          }
        }, [_c('p', {
          domProps: {
            "innerHTML": _vm._s(item === null || item === void 0 ? void 0 : (_item$content = item.content) === null || _item$content === void 0 ? void 0 : _item$content.replace(/\n/g, '<br>'))
          }
        })])], 1), _vm._v("> ")], 1)];
      }
    }, {
      key: `item.show`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', {
          staticClass: "mt-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.display.enabled,
            callback: function ($$v) {
              _vm.$set(item.display, "enabled", $$v);
            },
            expression: "item.display.enabled"
          }
        })];
      }
    }, {
      key: `item.period`,
      fn: function (_ref4) {
        var _item$display, _item$display$startDa, _item$display$startDa2, _item$display2, _item$display2$endDat, _item$display2$endDat2;
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "white-space-pre-line"
        }, [_vm._v(" " + _vm._s(`${(item === null || item === void 0 ? void 0 : (_item$display = item.display) === null || _item$display === void 0 ? void 0 : (_item$display$startDa = _item$display.startDate) === null || _item$display$startDa === void 0 ? void 0 : (_item$display$startDa2 = _item$display$startDa.toDate) === null || _item$display$startDa2 === void 0 ? void 0 : _item$display$startDa2.call(_item$display$startDa)) || ""}~\r\n${(item === null || item === void 0 ? void 0 : (_item$display2 = item.display) === null || _item$display2 === void 0 ? void 0 : (_item$display2$endDat = _item$display2.endDate) === null || _item$display2$endDat === void 0 ? void 0 : (_item$display2$endDat2 = _item$display2$endDat.toDate) === null || _item$display2$endDat2 === void 0 ? void 0 : _item$display2$endDat2.call(_item$display2$endDat)) || ""}`) + " ")])];
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "960px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on;
        return [_c('v-btn', {
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "fab": "",
            "fixed": "",
            "right": ""
          },
          on: {
            "click": _vm.create
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.popupDialog,
      callback: function ($$v) {
        _vm.popupDialog = $$v;
      },
      expression: "popupDialog"
    }
  }, [_vm.popupDialog ? _c('v-card', [_c('v-card-title', [_vm.editItem._id ? _c('span', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("팝업수정")]) : _c('span', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("팝업추가")])]), _c('v-card-text', [_c('v-container', {
    staticClass: "pb-0"
  }, [_c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "명칭",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "name", $$v);
      },
      expression: "editItem.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "순서",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "sequence", $$v);
      },
      expression: "editItem.sequence"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "URL",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.url,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "url", $$v);
      },
      expression: "editItem.url"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "위치(x)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.left,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "left", $$v);
      },
      expression: "editItem.left"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "위치(y)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.top,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "top", $$v);
      },
      expression: "editItem.top"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "크기(가로)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.width,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "width", $$v);
      },
      expression: "editItem.width"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "크기(세로)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.height,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "height", $$v);
      },
      expression: "editItem.height"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("전시여부")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.display.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.display, "enabled", $$v);
      },
      expression: "editItem.display.enabled"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("전시기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.display.period,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.display, "period", $$v);
      },
      expression: "editItem.display.period"
    }
  })], 1)], 1), _vm.editItem.display.period ? _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    ref: "startDateMenu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.editItem.display.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.editItem.display, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.editItem.display, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var on = _ref8.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "주문일자",
            "persistent-placeholder": "",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.editItem.display.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.editItem.display, "startDate", $$v);
            },
            expression: "editItem.display.startDate"
          }
        }, on))];
      }
    }], null, false, 194415147)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.startDateMenu.save(_vm.editItem.display.startDate);
      }
    },
    model: {
      value: _vm.editItem.display.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.display, "startDate", $$v);
      },
      expression: "editItem.display.startDate"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    ref: "endDateMenu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.editItem.display.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.editItem.display, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.editItem.display, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref9) {
        var on = _ref9.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "주문일자",
            "persistent-placeholder": "",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.editItem.display.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.editItem.display, "endDate", $$v);
            },
            expression: "editItem.display.endDate"
          }
        }, on))];
      }
    }], null, false, 1454337732)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.endDateMenu.save(_vm.editItem.display.endDate);
      }
    },
    model: {
      value: _vm.editItem.display.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.display, "endDate", $$v);
      },
      expression: "editItem.display.endDate"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('naver-smarteditor', {
    attrs: {
      "bSkipXssFilter": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }