<template>
    <v-dialog :disabled="!item?.errors?.length" max-width="1440">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-if="item.key == 'brands'">
            <verify-brands :value="{ [item.key]: item.errors }" isError />
        </template>
        <template v-if="item.key == 'bundleds'">
            <verify-bundleds :value="{ [item.key]: item.errors }" isError />
        </template>
        <template v-if="item.key == 'categories'">
            <verify-categories :value="{ [item.key]: item.errors }" isError />
        </template>
        <template v-if="item.key == 'choicees'">
            <verify-choicees :value="{ [item.key]: item.errors }" isError />
        </template>
        <template v-if="item.key == 'options'">
            <verify-options :value="{ [item.key]: item.errors }" isError />
        </template>
        <template v-if="item.key == 'products'">
            <verify-products :value="{ [item.key]: item.errors }" isError />
        </template>
        <template v-if="item.key == 'relateds'">
            <verify-relateds :value="{ [item.key]: item.errors }" isError />
        </template>
    </v-dialog>
</template>

<script>
import VerifyBrands from "./verify/verify-brands.vue";
import VerifyBundleds from "./verify/verify-bundleds.vue";
import VerifyCategories from "./verify/verify-categories.vue";
import VerifyChoicees from "./verify/verify-choicees.vue";
import VerifyOptions from "./verify/verify-options.vue";
import VerifyProducts from "./verify/verify-products.vue";
import VerifyRelateds from "./verify/verify-relateds.vue";

export default {
    components: {
        VerifyBrands,
        VerifyBundleds,
        VerifyChoicees,
        VerifyCategories,
        VerifyOptions,
        VerifyProducts,
        VerifyRelateds,
    },
    props: {
        item: { type: Object, default: () => ({}) },
    },
};
</script>

<style></style>
