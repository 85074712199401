var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.setOrderStatusCancel
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(_vm._s(_vm.title))]);

}
var staticRenderFns = []

export { render, staticRenderFns }