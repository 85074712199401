<template>
    <v-card flat outlined class="fill-height" style="overflow:hidden">
        <v-card-subtitle class="font-weight-bold">회원 정보</v-card-subtitle>
        <v-card-text class="pa-0" style="margin-bottom: -1px;">
            <vertical-form-table v-bind="{ value, items }" mode="view">
                <template #은행계좌> {{ value?.bank?.text }} / {{ value?.bank?.accountNumber }} / {{ value?.bank?.accountHolder }} </template>
            </vertical-form-table>
        </v-card-text>
    </v-card>
</template>

<script>
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";
export default {
    components: {
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // settlement
    },
    computed: {
        items() {
            return [
                {
                    term: "아이디",
                    key: "username",
                    cols: 6,
                },
                {
                    term: "회원직급",
                    key: "grade.title",
                    cols: 6,
                },
                {
                    term: "이름",
                    key: "name",
                    cols: 6,
                },
                {
                    term: "주민등록번호",
                    key: "residentRegistrationNumber",
                    cols: 6,
                },
                {
                    term: "연락처",
                    key: "phone",
                    cols: 6,
                },
                {
                    term: "이메일",
                    key: "email",
                    cols: 6,
                },
                {
                    type: "address",
                    term: "주소",
                    data: this.value,
                },
                {
                    term: "은행계좌",
                },
            ].map((item) => ({ ...item, headerProps: { class: "grey lighten-5 font-weight-bold grey--text text--darken-2" } }));
        },
    },
};
</script>

<style></style>
