import { render, staticRenderFns } from "./user-level-list-codes.vue?vue&type=template&id=72287536&"
import script from "./user-level-list-codes.vue?vue&type=script&lang=js&"
export * from "./user-level-list-codes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports