<template>
    <v-row no-gutters>
        <v-expand-transition>
            <v-col cols="12" v-show="form.charge.type == SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value">
                <v-currency-field v-model="form.charge.fixedAmount" prefix="고정금액" suffix="원" class="caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
            </v-col>
        </v-expand-transition>
        <v-expand-transition>
            <v-col cols="12" v-show="form.charge.type == SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value">
                <item-charge-prices-range v-model="form" v-bind="{ mode }" @input="emit" />
            </v-col>
        </v-expand-transition>
        <v-expand-transition>
            <v-col cols="12" v-show="form.charge.type == SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value">
                <item-charge-travel-range v-model="form" v-bind="{ mode }" @input="emit" />
            </v-col>
        </v-expand-transition>
    </v-row>
</template>

<script>
import ItemChargePricesRange from "./item-charge-prices-range.vue";
import ItemChargeTravelRange from "./item-charge-travel-range.vue";

import { attrs_input__verticalTable, initShopShippingOption, SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables";

export default {
    components: {
        ItemChargePricesRange,
        ItemChargeTravelRange,
    },
    props: {
        value: { type: Object, default: initShopShippingOption },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        form: initShopShippingOption(),

        attrs_input__verticalTable,
        SHIPPING_OPTION_CHARGE_TYPES,
    }),
    computed: {
        readonly() {
            return this.mode != "form";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>