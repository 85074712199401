<template>
    <v-data-table v-bind="{ items, headers }" fixed-header>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData },
        isError: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return this.value?.categories || [];
        },
        headers() {
            const headers = [
                { width: 240, value: "parents.0.name", text: "카테고리1", formatter: (value, item) => (item.parents.length == 0 ? item.name : value) || "-" },
                { width: 240, value: "parents.1.name", text: "카테고리2", formatter: (value, item) => (item.parents.length == 1 ? item.name : value) || "-" },
                { width: 240, value: "parents.2.name", text: "카테고리3", formatter: (value, item) => (item.parents.length == 2 ? item.name : value) || "-" },
                { width: 240, value: "code", text: "카테고리코드" },
            ];

            if (this.isError) headers.push({ width: 480, value: "errors", text: "오류", formatter: (errors) => errors.map((error) => error.message).join("\r\n") });

            return initDataTableHeaders(headers.map((header) => ({ ...header, class: (header.class || "") + " word-break-keep-all", cellClass: (header.cellClass || "") + " text-truncate max-width-0 py-3", withTitle: true })));
        },
    },
};
</script>

<style></style>
