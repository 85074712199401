<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <v-col cols="12" class="py-3 px-4">
                    {{ durationText }}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { initUsage } from "@/assets/variables";

export default {
    props: {
        usage: { type: Object, default: initUsage },
    },
    computed: {
        durationText() {
            const { endsAt, startsAt } = this.usage;

            let text = "";
            text += startsAt?.toDate?.() || "시작일시 없음";
            text += " ~ ";
            text += endsAt?.toDate?.() || "종료일시 없음";
            text = text.trim();
            return text;
        },
    },
};
</script>

<style>
</style>